export const OWNED_BY_TYPES = {
  USER: 'user',
  EVIDENCE_HUNT: 'evidencehunt',
};

export const OUTPUT_TEMPLATE_MODAL_PAGES = {
  ANSWER_FORMATTER: 'answer-formatter',
  ALL_TEMPLATES: 'all-templates',
};

export const isOutputTemplateEditable = (outputTemplateToCheck) => {
  return outputTemplateToCheck?.ownedByType === OWNED_BY_TYPES.USER;
};

export const isOutputTemplateMadeByEvidenceHunt = (outputTemplateToCheck) => {
  return outputTemplateToCheck?.ownedByType === OWNED_BY_TYPES.EVIDENCE_HUNT;
};
