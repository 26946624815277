import * as Sentry from '@sentry/browser';
import { config } from './config';
import {
  FAILED_STATUSES,
  FILE_STATUS,
  PROGRESS_STATUSES_FOR_FE,
  sortDocumentsByStatusPriority,
} from '@/features/Sources/utils';
import { getResource, postResource } from '@/api/apiResource.calls';
import { createInterruptableInterval } from '@/utils/interval';

const { apiBaseUrl } = config;

export async function fetchSources({ commit }, refreshing = false) {
  commit('SET_STATE_PROPERTY', { property: 'isSourcesLoading', value: true });
  if (refreshing) {
    commit('SET_STATE_PROPERTY', { property: 'isSourceRefreshing', value: true });
  }
  const mappedSources = new Map();
  try {
    const sources = await getResource(`${apiBaseUrl}/sources`);

    for (const source of sources) {
      mappedSources.set(source['@id'], source);
    }
  } catch (e) {
    Sentry.captureException('Error happened when fetching sources', e);
  } finally {
    commit('SET_STATE_PROPERTY', { property: 'isSourcesLoading', value: false });
    setTimeout(() => {
      commit('SET_STATE_PROPERTY', { property: 'isSourceRefreshing', value: false });
    }, 500);
  }

  commit('SET_STATE_PROPERTY', {
    property: 'sources',
    value: mappedSources,
  });
}
export async function checkSourceRefreshCondition({ state, getters, dispatch }, { sourceId }) {
  const { documents } = getters.getDocumentsOfSource(sourceId);
  const isBatchUploading = !!state.sourceBusyWithUploading;

  const isAllDone = (files) =>
    !files.length
      ? false
      : files.every(
          (doc) =>
            doc.status === FILE_STATUS.UPSERT_FINISHED || FAILED_STATUSES.includes(doc.status)
        );
  const feProgressFilteredDocs = documents.filter((doc) =>
    PROGRESS_STATUSES_FOR_FE.includes(doc.status)
  );

  const commonConditions =
    !documents.length ||
    isAllDone(documents) ||
    (!isBatchUploading && isAllDone(feProgressFilteredDocs));

  if (commonConditions) {
    return;
  }
  if (isBatchUploading) {
    dispatch('fetchSources', true);
    return;
  }
  dispatch('fetchSources', true);
  dispatch('fetchSourceFiles', { sourceId, refreshing: true });
}
export async function refetchSourcesInInterval({ dispatch, commit }, { sourceId }) {
  commit(
    'SET_SOURCE_REFRESH_ABORT',
    createInterruptableInterval(() => dispatch('checkSourceRefreshCondition', { sourceId }), 10000)
  );
}
export async function fetchSourceFiles(
  { commit, state, getters },
  { sourceId, refreshing = false }
) {
  const source = Array.from(state.sources.values()).find((candidate) => candidate.id === sourceId);
  let result = { documents: [], '@meta': { total: 0 } };
  if (!source?.documents['@id']) {
    return result;
  }
  if (refreshing) {
    commit('SET_STATE_PROPERTY', { property: 'isDocumentRefreshing', value: true });
  }
  try {
    const data = await getResource(source.documents['@id']);
    const allDifferentBatches = new Set(data.documents.map((item) => item.batch['@id']));
    const neededBatchInfos = Array.from(allDifferentBatches).filter(
      (batchId) => !state.batchInfos.has(batchId)
    );
    const allDifferentUsers = new Set();

    const batchPromises = neededBatchInfos.map(async (batchId) => {
      let batchInfo = { createdAt: '-', createdBy: { id: '-' } };
      try {
        batchInfo = await getResource(batchId);
        allDifferentUsers.add(batchInfo.createdBy['@id']);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        commit('SET_BATCH_INFO', batchInfo);
      }
    });
    await Promise.all(batchPromises);

    const neededUserInfo = Array.from(allDifferentUsers).filter(
      (userId) => !state.organizationUploadersInfo.has(userId)
    );

    const userPromises = neededUserInfo.map(async (userId) => {
      let userInfo = { email: '-', id: userId.split('/').pop() };
      try {
        userInfo = await getResource(userId);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        commit('SET_ORGANIZATION_UPLOADERS_INFO', userInfo);
      }
    });
    await Promise.all(userPromises);
    const { documents } = data;

    // Sort the documents by status.
    sortDocumentsByStatusPriority(documents);
    result = { documents, '@meta': { total: data['@meta'].total } };
    commit('ADD_SOURCE_FILES', {
      sourceId,
      data: result,
    });
  } catch (error) {
    Sentry.captureException(error);
    return getters.getDocumentsOfSource(sourceId);
  } finally {
    setTimeout(
      () => commit('SET_STATE_PROPERTY', { property: 'isDocumentRefreshing', value: false }),
      500
    );
  }
  return result;
}
export async function createElasticSearchIndex(sourceId, numberOfShards) {
  if (!sourceId) return Promise.reject(new Error('No sourceId provided'));

  return await postResource(`${apiBaseUrl}/sources/${sourceId}/index`, { numberOfShards });
}

export async function createBucket({ sourceId, location, retentionDays }) {
  if (!sourceId) return Promise.reject(new Error('No sourceId provided'));
  return await postResource(`${apiBaseUrl}/sources/${sourceId}/bucket`, {
    location,
    softDelete: { retentionDays },
  });
}
