import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { getRouteName } from '@/utils';
import { CAREERS_LINK } from '@/utils/externalLinks';

export const useNavigate = () => {
  const router = useRouter();
  return (link) => {
    link.callback?.();
    const { to } = link;
    if (to) {
      router.push(to);
    }
  };
};

const usePolicyPages = (location = 'footer') => {
  const isFooter = location === 'footer';
  const { t } = useI18n();
  const store = useStore();
  const route = useRoute();

  const trackPolicyDocumentClicked = (link) => {
    const routeName = getRouteName(route, true);
    store.dispatch('trackEvent', {
      event: 'PolicyDocumentClicked',
      values: {
        page: routeName,
        content: link,
        location,
      },
    });
  };

  const trackContactClicked = () => {
    const routeName = getRouteName(route, true);
    store.dispatch('trackEvent', {
      event: 'ContactClicked',
      values: {
        page: routeName,
        content: 'email_form',
        location,
      },
    });
  };

  const pages = computed(() =>
    isFooter ?
    [
      {
        id: 'privacy',
        text: t('footer.privacy'),
        to: { name: 'Privacy' },
        callback: () => trackPolicyDocumentClicked('privacy'),
      },
      {
        id: 'terms',
        text: t('footer.terms'),
        to: { name: 'Terms' },
        callback: () => trackPolicyDocumentClicked('terms'),
      },
      {
        id: 'contact',
        text: t('footer.contact'),
        callback: () => {
          store.commit('SET_STATE_PROPERTY', {
            property: 'contactModal',
            value: true,
          });
          trackContactClicked();
        },
      }
    ] :
    [
      {
        id: 'privacy',
        text: t('footer.privacy'),
        to: { name: 'Privacy' },
        callback: () => trackPolicyDocumentClicked('privacy'),
      },
      {
        id: 'terms',
        text: t('footer.terms'),
        to: { name: 'Terms' },
        callback: () => trackPolicyDocumentClicked('terms'),
      },
      // {
      //   id: 'cookies',
      //   text: t('footer.cookies'),
      //   to: { name: 'Cookies' },
      //   callback: () => trackPolicyDocumentClicked('cookies'),
      // },
      {
        id: 'contact',
        text: t('footer.contact'),
        callback: () => {
          store.commit('SET_STATE_PROPERTY', {
            property: 'contactModal',
            value: true,
          });
          trackContactClicked();
        },
      },
      {
        id: 'careers',
        text: t('footer.careers'),
        callback: () => {
          trackPolicyDocumentClicked('careers');
          window.open(CAREERS_LINK, '_blank');
        },
      },
    ]);
  return pages;
};

export default usePolicyPages;
