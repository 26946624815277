<template>
  <v-avatar :color="avatarColor" :size="size">
    <span :class="avatarClass">{{ initials }}</span>
  </v-avatar>
</template>

<script setup>
import { computed } from 'vue';
import { getColorFromHash } from '@/styles/stringToColor';

const fontSizeMap = {
  'x-small': 'x-small-avatar',
  small: 'small-avatar',
  default: 'medium-avatar',
};

const props = defineProps({
  size: {
    type: String,
    default: 'small',
    validator: (value) => ['small', 'default', 'x-small'].includes(value),
  },
  initials: {
    type: String,
    default: '',
  },
  textForColor: {
    type: String,
    default: '',
  },
});

const avatarClass = computed(() => fontSizeMap[props.size]);
const avatarColor = computed(() => getColorFromHash(props.textForColor));
</script>

<style lang="scss" scoped>
.medium-avatar {
  @include text18($font-weight: 400);
}
.small-avatar {
  @include text14($font-weight: 400);
}
.x-small-avatar {
  @include text12($font-weight: 400);
}
</style>
