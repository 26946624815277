<template>
  <slot name="activator" :open-dialog="handleActivatorClick"> </slot>
  <v-dialog v-model="dialogModel" min-width="300px" max-width="600px" class="custom-dialog">
    <v-card>
      <v-card-title>{{ title }} </v-card-title>
      <v-card-text>{{ cardText }} </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <BaseButton variant="outlined" :color="buttonColor" @click="handleCancel">{{
          translatedCancelButtonText
        }}</BaseButton>
        <BaseButton @click="handleConfirm" :color="buttonColor">{{
          translatedConfirmButtonText
        }}</BaseButton>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseButton from '@/components/BaseButton.vue';

const { t } = useI18n();
const { title, cardText, confirmButtonText, cancelButtonText, buttonColor } = defineProps({
  title: {
    type: String,
    required: true,
  },
  cardText: {
    type: String,
    required: true,
  },
  confirmButtonText: {
    type: String,
    default: '',
  },
  buttonColor: {
    type: String,
    required: false,
  },
  cancelButtonText: {
    type: String,
    default: '',
  },
});

const dialogModel = ref(false);
const emit = defineEmits(['click:cancel', 'click:confirm']);

const closeDialog = () => {
  dialogModel.value = false;
};

const handleCancel = () => {
  emit('click:cancel');
  closeDialog();
};

const handleConfirm = () => {
  emit('click:confirm');
  closeDialog();
};
const handleActivatorClick = () => {
  dialogModel.value = true;
};

const translatedCancelButtonText = computed(() => cancelButtonText || t('global.cancel'));
const translatedConfirmButtonText = computed(() => confirmButtonText || t('global.confirm'));
</script>
<style lang="scss" scoped>
.custom-dialog {
  :deep() {
    .v-card-title {
      padding-top: 16px;
    }
    .v-card-actions {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}
</style>
