import * as Sentry from '@sentry/browser';
import { config } from './config';
import { deleteResource, getResource, patchResource, postResource } from '@/api/apiResource.calls';
import { isOutputTemplateMadeByEvidenceHunt } from '@/utils/outputTemplate';

const { apiBaseUrl } = config;
const outputTemplatesBaseURL = `${apiBaseUrl}/output-templates`;

async function getOutputTemplateListResource({ limit, orderByColumn, orderByDirection } = {}) {
  let outputTemplateListResource;
  try {
    const searchParams = new URLSearchParams();

    if (typeof limit === 'number' && limit > 0) {
      searchParams.set('limit', limit);
    }

    if (typeof orderByColumn === 'string' && orderByColumn.length > 0) {
      searchParams.set('orderByColumn', orderByColumn);
    }

    if (typeof orderByDirection === 'string' && orderByDirection.length > 0) {
      searchParams.set('orderByDirection', orderByDirection);
    }

    outputTemplateListResource = await getResource(
      `${outputTemplatesBaseURL}?${searchParams.toString()}`
    );
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    return;
  }

  return outputTemplateListResource;
}

export async function fetchOutputTemplateListResource(
  { commit },
  searchParams = { orderByColumn: 'updatedAt', orderByDirection: 'desc' }
) {
  const outputTemplateListResource = await getOutputTemplateListResource(searchParams);

  commit('SET_STATE_PROPERTY', {
    property: 'outputTemplateListResource',
    value: outputTemplateListResource,
  });
}

export async function fetchRecentlyUsedOutputTemplateListResource({ commit }) {
  const searchParams = {
    limit: 3,
    orderByColumn: 'updatedAt',
    orderByDirection: 'desc',
  };

  const recentlyUsedOutputTemplateListResource = await getOutputTemplateListResource(searchParams);
  commit('SET_STATE_PROPERTY', {
    property: 'recentlyUsedOutputTemplateListResource',
    value: recentlyUsedOutputTemplateListResource,
  });
}

async function createNewOutputTemplate(prompt, name) {
  let newOutputTemplate;
  try {
    newOutputTemplate = await postResource(outputTemplatesBaseURL, {
      prompt,
      name,
    });
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }

  return newOutputTemplate;
}

async function patchOutputTemplate(resource, prompt, name) {
  let updatedOutputTemplate;
  try {
    updatedOutputTemplate = await patchResource({ ...resource, prompt, name });
  } catch (error) {
    Sentry.captureException(error);
    return;
  }

  return updatedOutputTemplate;
}

export async function saveOutputTemplate(
  { commit, dispatch },
  { selectedOutputTemplate, prompt, name }
) {
  // Create a new one if there is no template selected or copy Evidence Hunt made template
  if (!selectedOutputTemplate || isOutputTemplateMadeByEvidenceHunt(selectedOutputTemplate)) {
    const newOutputTemplate = await createNewOutputTemplate(prompt, name);

    if (newOutputTemplate) {
      commit('SET_SELECTED_OUTPUT_TEMPLATE', newOutputTemplate);
      commit('ADD_TO_FETCHED_OUTPUT_TEMPLATES', newOutputTemplate);

      // Since ordering is not now, refetch the whole list to properly reflect the new item in the list
      dispatch('fetchOutputTemplateListResource');
    }
    return;
  }

  // Update the already existing output template
  const patchedOutputTemplate = await patchOutputTemplate(selectedOutputTemplate, prompt, name);

  if (patchedOutputTemplate) {
    commit('SET_SELECTED_OUTPUT_TEMPLATE', patchedOutputTemplate);
    commit('PATCH_FETCHED_OUTPUT_TEMPLATES', patchedOutputTemplate);
  }
}

export async function deleteTemplate({ commit }, templateToDelete) {
  let deletedTemplate;
  try {
    deletedTemplate = await deleteResource(templateToDelete);
  } catch (error) {
    Sentry.captureException(error);
    return;
  }

  commit('DELETE_OUTPUT_TEMPLATE', deletedTemplate);
}
