<template>
  <v-divider :thickness="1" style="opacity: 80%" />

  <v-list-subheader class="user-menu-subheader">
    {{ t('userMenu.organizational') }}</v-list-subheader
  >
  <SourcesLink />
  <RouterLink
    class="link"
    :to="organizationSettingsLink"
    v-if="(isSuperAdmin || isOrganizationAdmin) && activeOrganizationId">
    <v-list-item
      key="org-switcher-org-settings"
      value="org-switcher-org-settings"
      color="primary"
      variant="text">
      <template #prepend>
        <v-icon :icon="'mdi-cog'" style="margin-right: -24px" color="black"></v-icon>
      </template>
      <v-list-item-title>
        {{ t('organizationSettings.title') }}
      </v-list-item-title>
    </v-list-item>
  </RouterLink>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import SourcesLink from '@/features/Header/components/SourcesLink.vue';

const { t } = useI18n();
const store = useStore();

const isSuperAdmin = computed(() => store.getters.isSuperAdmin);
const isOrganizationAdmin = computed(() => store.getters.isOrganizationAdmin);
const activeOrganizationId = computed(() => store.state.organization?.id);

const organizationSettingsLink = computed(() => {
  if (activeOrganizationId.value) {
    return { name: 'OrganizationSettings', params: { id: activeOrganizationId.value } };
  } else {
    return { name: 'UserProfile' };
  }
});
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
  color: inherit;
}

.user-menu-subheader {
  text-transform: uppercase;
  font-size: rem(10px);
}
</style>
