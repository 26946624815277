export function fetchUtmParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    let utmData = {};

    utmParams.forEach(param => {
        if (urlParams.has(param)) {
        utmData[param] = urlParams.get(param);
        }
    });

    if (Object.keys(utmData).length > 0) {
        localStorage.setItem('utm_params', JSON.stringify(utmData));
    }
}

export function getStoredUtmParams() {
    const utm = localStorage.getItem('utm_params');
    return utm ? JSON.parse(utm) : null;
}
  