<template>
  <span><span v-if="!hideOrganizationText">Only available for organisation.</span>Interested?</span>
  <span class="get-in-touch-link" @click="handleGetInTouch">Get in touch</span>
</template>
<script setup>
import { useStore } from 'vuex';

defineProps({
  hideOrganizationText: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const handleGetInTouch = () => {
  store.commit('SET_STATE_PROPERTY', { property: 'enterpriseContactModal', value: true });
};
</script>
<style lang="scss" scoped>
.get-in-touch-link {
  color: $color-primary;
  cursor: pointer;
  margin-left: 2px;
}
.get-in-touch-link:hover {
  text-decoration: underline;
}
</style>
