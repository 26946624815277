<template>
  <BaseModal width="auto" max-height="95%" class="contact-modal-full" title="" v-model="model">
    <ContactFormFull />
  </BaseModal>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ContactFormFull from '@/features/ContactFormFull.vue';
import BaseModal from '@/components/BaseModal';

const store = useStore();
const { t } = useI18n();

const model = computed({
  get: () => store.state.contactModalFull,
  set: (val) => {
    store.commit('SET_STATE_PROPERTY', {
      property: 'contactModalFull',
      value: val,
    });
  },
});
</script>

<style lang="scss">
.contact-modal-full {
  .modal-inner{
    background: radial-gradient(ellipse at top, #E2E9FF, transparent),
            radial-gradient(ellipse at bottom, #EFF3FF, transparent);
  }
  .contact-form__btn {
   
    width: 100%;
    max-width: unset !important;
    margin-top: auto;
  }
}
</style>
