import { omit } from 'lodash';
import { nextTick } from 'vue';
import * as Sentry from '@sentry/browser';
import { toast } from 'vue3-toastify';
import { config } from './config';

import { getResource, postResource, putResource } from '@/api/apiResource.calls';
import {
  FILE_STATUS,
  NUMBER_OF_HASH_WORKERS,
  NUMBER_OF_UPLOAD_WORKERS,
} from '@/features/Sources/utils';
import { updateFile } from '@/api/documents.calls';
import { createInterruptableInterval } from '@/utils/interval';
import { calculateFileHash } from '@/utils/file';

const { apiBaseUrl } = config;

export const uploadBatch = async ({ state, dispatch, commit }, { source }) => {
  const selectedFiles = state.selectedFilesToUpload;

  const formattedFiles = selectedFiles.map((file, index) => ({
    batchIndex: index,
    name: file.name,
    type: file.type,
    size: file.size,
  }));

  try {
    dispatch(
      'uploadBatchCreated',
      await postResource(`${source['@id']}/batches`, { files: formattedFiles })
    );
    commit('SET_STATE_PROPERTY', {
      property: 'sourceBusyWithUploading',
      value: source,
    });
    dispatch('reloadSourceFiles');
  } catch (error) {
    dispatch('resetUploadingStates');
    throw new Error(error.message);
  }
};

export const organizationSourceMounted = async ({ commit }, { refreshDocuments }) => {
  commit('SET_DOCUMENT_REFRESHER', refreshDocuments);
};

export const organizationSourceUnmounted = async ({ commit }) => {
  commit('REMOVE_DOCUMENT_REFRESHER');
};

export const reloadSourceFiles = async ({ state }) => {
  const { refreshDocuments } = state;

  if (typeof refreshDocuments === 'function') {
    refreshDocuments();
  }
};

export const uploadBatchCreated = async ({ commit, dispatch, state }, _uploadBatch) => {
  commit('SET_BATCH_INFO', omit(_uploadBatch, ['documents']));

  dispatch(
    'fileListPrepared',
    // Select the applicable files from the selected files for upload.
    state.selectedFilesToUpload.reduce(
      (carry, local) => {
        // Find the remote document that matches the local file.
        const remote = Array.from(_uploadBatch.documents ?? []).find(
          (candidate) => candidate.name === local.name
        );

        // If the remote document is still preparing, add it to the file list.
        if (remote?.status === FILE_STATUS.PREPARING) {
          carry.push({ remote, local });
        }

        return carry;
      },
      // Start with an empty file list.
      []
    )
  );
};
export const fileListPrepared = async ({ commit, dispatch }, files) => {
  if (files.length < 1) {
    /**TODO:Find a good way to translate this message */
    /** t('sources.noFilesToUpload') */
    toast.error('No valid files to upload', { autoClose: 5000 });
    nextTick(() => dispatch('resetUploadingStates'));
    return;
  }

  commit('SET_STATE_PROPERTY', {
    property: 'filesToUpload',
    value: files.reduce((carry, file) => {
      carry.set(file.remote['@id'], file);
      return carry;
    }, new Map()),
  });
  dispatch(
    'createHashQueue',
    files.map((file) => file.remote['@id'])
  );
};

export const resetUploadingStates = async ({ commit }) => {
  const newState = {
    sourceBusyWithUploading: undefined,
    selectedFilesToUpload: [],
    filesToUpload: new Map(),
    batchHashes: new Set(),
    hashQueue: [],
    uploadQueue: [],
    activeHashWorkers: new Set(),
    activeUploadWorkers: new Set(),
    failedFiles: [],
  };
  for (const [property, value] of Object.entries(newState)) {
    commit('SET_STATE_PROPERTY', { property, value });
  }
};
export const createHashQueue = async ({ commit, dispatch }, files) => {
  commit('SET_STATE_PROPERTY', {
    property: 'hashQueue',
    value: files,
  });
  dispatch('startHashing');
};

export const startHashing = async ({ dispatch, commit }) => {
  const bufferOptions = { maxWorkers: NUMBER_OF_HASH_WORKERS };

  // Allow anyone to abort the hashing process.
  commit(
    'SET_HASH_ABORT',
    // Compare the buffer and queue every 2 seconds.
    createInterruptableInterval(() => dispatch('bufferHashWorkers', bufferOptions), 2000)
  );

  // Immediately start comparing the hash buffer and queue.
  dispatch('bufferHashWorkers', bufferOptions);
};

export const stopHashing = async ({ commit }) => {
  commit('ABORT_HASHING');
};

export const bufferHashWorkers = async ({ state, commit, dispatch }, { maxWorkers }) => {
  const numWorkers = maxWorkers - state.activeHashWorkers.size;

  // Get up to `numWorkers` number of files from the hash queue.
  // This consumes them, meaning the hash queue hash shrunk after this line.
  const files = state.hashQueue.splice(0, numWorkers);

  // Process the returned files.
  for (const file of files) {
    const worker = dispatch('calculateHash', {
      file: state.filesToUpload.get(file),
    });

    commit('ADD_HASH_WORKER', worker);

    // Whether the worker succeeds or fails, it should be removed from the buffer.
    worker.finally(() => {
      commit('REMOVE_HASH_WORKER', worker);
      dispatch('bufferHashWorkers', { maxWorkers });
    });
  }

  // No more files queued and no more hashes being made.
  if (state.hashQueue.length === 0 && state.activeHashWorkers.size === 0) {
    dispatch('stopHashing');
  }
};

export const startUploading = async ({ state, dispatch, commit }) => {
  if (state.abortUploading?.constructor === AbortController) {
    return;
  }

  const bufferOptions = { maxWorkers: NUMBER_OF_UPLOAD_WORKERS };

  // Allow anyone to abort the uploading process.
  commit(
    'SET_UPLOAD_ABORT',
    // Compare the buffer and queue every 2 seconds.
    createInterruptableInterval(() => dispatch('bufferUploadWorkers', bufferOptions), 2000)
  );

  // Immediately start comparing the upload buffer and queue.
  dispatch('bufferUploadWorkers', bufferOptions);
};

export const stopUploading = async ({ commit, dispatch }) => {
  commit('ABORT_UPLOADING');
  dispatch('resetUploadingStates');
};

export const bufferUploadWorkers = async ({ state, commit, dispatch }, { maxWorkers }) => {
  const numWorkers = maxWorkers - state.activeUploadWorkers.size;

  // Get up to `numWorkers` number of files from the upload queue.
  // This consumes them, meaning the hash queue hash shrunk after this line.
  const files = state.uploadQueue.splice(0, numWorkers);

  // Upload files.
  for (const file of files) {
    const worker = dispatch('uploadFile', {
      file: state.filesToUpload.get(file),
    });

    commit('ADD_UPLOAD_WORKER', worker);

    worker.finally(() => {
      commit('REMOVE_UPLOAD_WORKER', worker);
      dispatch('bufferUploadWorkers', { maxWorkers });
    });
  }

  // See if any part of the upload is still in progress.
  if (
    state.hashQueue.length ||
    state.activeHashWorkers.size ||
    state.uploadQueue.length ||
    state.activeUploadWorkers.size
  ) {
    // Carry on.
    return;
  }

  dispatch('stopUploading');
};

export const calculateHash = async (
  { state, dispatch, commit },
  { file, algorithm = 'sha-256', vendor = 'hash-wasm' }
) => {
  let hash;
  // Calculate the hash.
  try {
    commit('UPDATE_FILE_STATUS_IN_SOURCE_FILES', {
      fileId: file.remote.id,
      status: FILE_STATUS.HASHING,
    });

    hash = await calculateFileHash(file.local, algorithm, vendor);
  } catch (error) {
    dispatch('uploadError', { file, status: FILE_STATUS.HASH_FAILED });
    return;
  }

  // Store the hash on the file data.
  file.remote.hash = hash;

  // Check if the hash is a duplicate within the same upload batch.
  if (state.batchHashes.has(hash)) {
    await dispatch('uploadError', {
      file,
      status: FILE_STATUS.DUPLICATE_CONTENT,
    });
    return;
  }

  // Commit the hash to the upload batch.
  commit('ADD_TO_BATCH_HASHES', hash);

  // Emit that the file hash hashed.
  dispatch('fileHashed', file.remote['@id']);
};

export const fileHashed = async ({ commit, dispatch }, file) => {
  commit('ADD_TO_UPLOAD_QUEUE', file);
  dispatch('startUploading');
};

export const changeFileStatus = async ({ commit, dispatch }, { file, status }) => {
  try {
    const response = await updateFile(file.remote, { status });
    commit('UPDATE_FILE_STATUS_IN_SOURCE_FILES', {
      fileId: file.remote.id,
      status: response.status,
    });
  } catch (e) {
    console.error('Error when updating file status:', file.remote['@id'], e);
  }
};

export const uploadError = async ({ dispatch, commit }, { file, status }) => {
  commit('ADD_TO_FAILED_FILE_LIST', file.remote['@id']);

  if (!status) {
    commit('UPDATE_FILE_STATUS_IN_SOURCE_FILES', {
      fileId: file.remote.id,
      status: FILE_STATUS.UPLOAD_FAILED,
    });
  }

  if (status) {
    dispatch('changeFileStatus', { file, status });
  }
};

export const uploadFile = async ({ state, dispatch, commit }, { file }) => {
  const { signal } = state.abortUploading ?? {};

  commit('UPDATE_FILE_STATUS_IN_SOURCE_FILES', {
    fileId: file.remote.id,
    status: FILE_STATUS.UPLOADING,
  });

  try {
    const response = await putResource(
      file.remote.file,
      file.local,
      {
        'X-File-Hash': file.remote.hash,
      },
      signal
    );
    file.bytesSent = file.local.size;

    commit('UPDATE_FILE_STATUS_IN_SOURCE_FILES', {
      fileId: file.remote.id,
      status: response.status,
    });
  } catch (error) {
    await dispatch('uploadError', { file });
    throw new Error(error.message);
  }
};

export const fetchSourceBucketOptions = async ({ commit }) => {
  try {
    commit('SET_STATE_PROPERTY', {
      property: 'sourceBucketOptions',
      value: await getResource(`${apiBaseUrl}/sources/settings`),
    });
  } catch (e) {
    Sentry.captureException('Error when fetching sources/settings :', e);
  }
};

export const resetOrganizationalSourceStates = async ({ commit, state }) => {
  commit('SET_STATE_PROPERTY', { property: state.batchInfos, value: new Map() });
  commit('SET_STATE_PROPERTY', { property: state.organizationUploadersInfo, value: new Map() });
  commit('SET_STATE_PROPERTY', { property: state.filesBySource, value: {} });
};
