import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { CAREERS_LINK } from '@/utils/externalLinks';
export const useFooterLinks = () => {
  const { t } = useI18n();
  const links = computed(() => ([
    {
      label: t('footer.solutions'),
      links: [
        {
          route: {
            name: 'Solutions',
            hash: '#academic'
          },
          title: t('footer.academic')
        },
        {
          route: {
            name: 'Solutions',
            hash: '#healthcare'
          },
          title: t('footer.healthcare')
        },
        {
          route: {
            name: 'Solutions',
            hash: '#sciences'
          },
          title: 'Life Sciences'
        }
      ]
    },
    {
      label: t('footer.about'),
      links: [
        {
          route: {
            name: 'About'
          },
          title: t('about.title_1')
        },
        {
          route: {
            name: 'Pricing'
          },
          title: t('global.pricing')
        },
        {
          route: {
            name: 'Knowledge Base'
          },
          title: t('global.knowledgeBase')
        },
        {
          route: {
            name: 'Blog'
          },
          title: t('global.news')
        },
        {
          href: CAREERS_LINK,
          title: t('footer.careers')
        }
      ]
    }
  ]))

  return links
}
