import { createRouter, createWebHistory } from 'vue-router';
import { toast } from 'vue3-toastify';
import { auth } from '@/lib/firebase';
const { routes } = require('./routes');

export default function createAppRouter(store, i18n) {
  const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition || to.hash) {
        return savedPosition;
      } else {
        return { top: 0, behavior: 'smooth' };
      }
    },
  });

  const checkAuthorization = (condition, next) => {
    const isSuperAdmin = store.state.userProfile?.superAdmin;

    if (condition || isSuperAdmin) {
      next();
    } else {
      next({ path: '/chat' });
      setTimeout(
        () => toast.error(i18n.global.t('unauthorized.subtitle'), { autoClose: 5000 }),
        500
      );
    }
  };

  const checkOrganizationId = (to, next) => {
    const condition =
      store.state.organization &&
      store.state.organization.id &&
      store.state.organization.id === to.params.id;
    checkAuthorization(condition, next);
  };

  const checkSuperAdmin = (next) => {
    const condition = store.state.userProfile?.superAdmin;
    checkAuthorization(condition, next);
  };
  const checkProperAuthorization = (to, next) => {
    if (to.matched.some((record) => record.meta.requiresOrganizationId)) {
      checkOrganizationId(to, next);
    } else if (to.matched.some((record) => record.meta.requiresSuperAdmin)) {
      checkSuperAdmin(next);
    }
  };

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          if (
            to.matched.some(
              (record) => record.meta.requiresOrganizationId || record.meta.requiresSuperAdmin
            )
          ) {
            if (!store.state.userProfile?.id) {
              store.commit('SET_STATE_PROPERTY', {
                property: 'showPageSkeleton',
                value: true,
              });
              await store.dispatch('fetchUserProfile', user);
            }
            store.commit('SET_STATE_PROPERTY', {
              property: 'showPageSkeleton',
              value: false,
            });
            checkProperAuthorization(to, next);
          } else {
            next();
          }
        } else {
          next({
            path: 'login',
          });
        }
      });
    } else if (to.name === 'Login') {
      auth.onAuthStateChanged((user) => {
        if (user) {
          next({ path: '/chat' });
        } else {
          next();
        }
      });
    } else {
      next();
    }
  });
  return router;
}
