import { getAuthenticatedHeaders } from '@/lib/authentication';

function normalizeBody(body) {
  return typeof body === 'object' && body.constructor !== File ? JSON.stringify(body) : body;
}

async function resourceRequest(
  model,
  method = 'GET',
  body = undefined,
  headers = {},
  fetchOptions = {}
) {
  if (model['@id'] === 'undefined') {
    throw new Error('Model without @id is not supported.');
  }

  const { headers: authHeaders } = await getAuthenticatedHeaders();

  const response = await fetch(model['@id'], {
    headers: {
      Accept: 'application/json',
      ...authHeaders,
      ...headers,
    },
    method,
    body,
    ...fetchOptions,
  });

  let decoded = undefined;

  // When there is no content, do not attempt to process the response body.
  // When the status code shows an error level over 500, assume it was not
  //   created by the API, but infrastructure.
  if (response.status !== 204 && response.status <= 500) {
    try {
      decoded = await response.json();
    } catch (error) {
      throw new Error(`Unexpected API error. HTTP ${response.status}. ${error.message}`);
    }
  }

  if (!response.ok) {
    const { error = `Unexpected API error. HTTP ${response.status}.` } = decoded ?? {};

    throw new Error(error);
  }

  return decoded;
}

function normalizeModel(modelOrAtId) {
  return typeof modelOrAtId === 'string' ? { '@id': modelOrAtId } : modelOrAtId;
}

export async function getResource(model, headers = {}) {
  return resourceRequest(normalizeModel(model), 'GET', undefined, headers);
}

export async function postResource(url, model, headers = {}) {
  return resourceRequest({ '@id': url }, 'POST', normalizeBody(model), {
    'Content-Type': 'application/json',
    ...headers,
  });
}

export async function putResource(model, payload, headers = {}, signal = undefined) {
  if (signal && signal.constructor !== AbortSignal) {
    throw new Error('Provided signal must be an instance of AbortSignal.');
  }

  return resourceRequest(model, 'PUT', payload, headers, signal ? { signal } : {});
}

export async function patchResource(model, headers = {}) {
  return resourceRequest(model, 'PATCH', normalizeBody(model), {
    'Content-Type': 'application/json',
    ...headers,
  });
}

export async function deleteResource(model, headers = {}) {
  return resourceRequest(normalizeModel(model), 'DELETE', undefined, headers);
}
