<template>
  <Avatar :initials="initials" :text-for-color="textForColor" :size="size" />
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Avatar from '@/components/Avatar.vue';
import { useUserProfile } from '@/composables/useUserProfile';
defineProps({
  size: {
    type: String,
    default: 'small',
    validator: (value) => ['x-small', 'small', 'default'].includes(value),
  },
});

const store = useStore();
const { initials } = useUserProfile();

const textForColor = computed(
  () => store.state.userProfile?.firstName || '' + store.state.userProfile?.lastName || ''
);
</script>

<style lang="scss" scoped></style>
