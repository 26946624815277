<template>
  <div class="question-input">
    <input v-model="value" type="text" placeholder="Ask your question..." @keydown.enter="handleInput">
    <button>
      <img src="@/assets/icons/logo-spear-short.svg" alt="send icon" width="42" height="42" @click="handleInput">
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import {useSubmitQuestion} from '@/composables/useSubmitQuestion.js'

const value = ref('')
const { submitQuestion } = useSubmitQuestion()

const handleInput = () => {
  if (!value.value) {
    return
  }

  submitQuestion(value.value)
}
</script>

<style lang="scss" scoped>
.question-input {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 6px;
  padding-left: 16px;
  border-radius: 25px;
  height: 54px;
  box-sizing: border-box;
  border: 1px solid #B5B7C2;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 19px;
    line-height: 24px;
    font-weight: 500;
    color: #01015D;

    &::placeholder {
      color: inherit;
    }
  }

  button {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
  }
}
</style>
