<template>
  <div
    class="base-field"
    :class="{
      'base-field--inline': inline,
      'base-field--mobile-inline': mobileInline,
    }">
    <div
      v-if="label || mobileInline"
      class="base-field__label text-text"
      :class="{
        'base-field__label--title': title,
      }">
      <span
        v-if="label"
        :class="{
          'base-field__label__darker-text': darkerLabel,
          'base-field__label-text': !darkerLabel,
        }">
        {{ label }}
      </span>
      <slot v-if="mobileInline" />
      <HelpIcon v-if="helpText" :description="helpText" />
    </div>
    <div v-if="!mobileInline" class="base-field__field">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';

const HelpIcon = defineAsyncComponent(() => import('@/components/HelpIcon.vue'));

defineProps({
  label: {
    type: String,
    default: '',
  },
  inline: {
    type: Boolean,
    default: false,
  },
  mobileInline: {
    type: Boolean,
    default: false,
  },
  // use label as a section title
  title: {
    type: Boolean,
    default: false,
  },
  helpText: {
    type: String,
    default: '',
  },
  darkerLabel: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.base-field {
  display: flex;
  flex-direction: column;
  gap: rem(8px);
  &__label {
    font-size: rem(12px);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(10px);
    &--title {
      @include text14;
      padding: 0;
      margin-bottom: rem(2px);
    }
    &__darker-text {
      color: $color-secondary-darken-1 !important;
      font-weight: 600;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: rem(8px);
  }
  &--inline & {
    &__field {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  &--mobile-inline {
    width: 100%;
  }
  &--mobile-inline & {
    &__label-text {
      font-size: rem(14px);
      font-weight: 500;
      line-height: rem(20px);
      margin-right: auto;
    }
  }
}
</style>
