<template>
  <SwitchButton
    v-model="isSelectedFormatAlwaysOnModel"
    :label="t('answerFormatting.alwaysOn')"
    style="margin-left: auto"
    :is-text-form-color="false"
    :disabled="disabled" />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import SwitchButton from '@/components/form/SwitchButton';

const { t } = useI18n();
const store = useStore();

const disabled = computed(() => !store.getters.isAnswerFormatValid);
const isSelectedFormatAlwaysOnModel = computed({
  get: () => store.state.isAnswerFormatAlwaysOn,
  set: (newValue) => {
    store.commit('SET_IS_ANSWER_FORMAT_ALWAYS_ON', newValue);
  },
});
</script>

<style lang="scss" scoped>
.period--selector {
  &--value {
    flex-grow: 1;
  }

  :deep() {
    .v-select__selection-text {
      font-weight: 400;
    }
  }
}
</style>
