<template>
  <v-select
    class="base-select text-secondary"
    :class="{
      'base-select--dark': dark,
      'base-select--light': !dark,
    }"
    :bg-color="dark ? 'bg-darken-1' : 'bg'"
    variant="outlined"
    flat
    rounded="lg"
    density="comfortable"
    menu-icon="mdi-chevron-down"
    item-color="primary"
    elevation="0"
    hide-details="auto">
    <template #item="{ props }">
      <v-list-item class="base-select__item" :class="itemClass" v-bind="props" />
    </template>
    <template v-if="hasSlot('selection')" #selection="selectionProps">
      <slot name="selection" v-bind="selectionProps" />
    </template>
  </v-select>
</template>

<script setup>
import { useSlots } from 'vue';

const slots = useSlots();
const hasSlot = (name = 'default') => !!slots[name];

defineProps({
  itemClass: {
    type: String,
    default: '',
  },
  dark: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.base-select {
  min-width: rem(80px);
  &.v-select--active-menu {
    :deep() {
      .v-field {
        &__clearable {
          display: flex;
        }
      }
    }
  }
  :deep() {
    .v-select {
      &__menu-icon {
        margin: 0;
      }
      &__selection,
      &__selection > * {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
    .v-list {
      box-shadow: unset;
    }
    .v-field {
      height: rem(44px);
      padding-right: rem(4px);
      display: flex;
      .v-field__outline {
        color: $color-secondary;
        --v-field-border-opacity: 0.7;
      }
      &:hover {
        .v-field__outline {
          --v-field-border-opacity: 1;
        }
      }
      &__input {
        padding-left: rem(12px);
        padding-right: 0;
        padding-block: 0;
        input {
          flex-grow: 1;
          align-self: unset;
        }
      }
      &__clearable {
        display: none;
        margin: 0;
      }
      &:focus-within {
        outline-width: 2px;
      }
    }
    .v-field__field {
      height: 100%;
      max-width: 92%;
      .v-label {
        color: $color-text;
      }
    }
    .v-field__input {
      min-height: unset;
      height: 100%;
      font-size: rem(16px);
      font-weight: 500;
      color: $color-text;
    }
  }
  &__item {
    :deep() {
      min-height: unset;
      .v-list-item-title {
        font-size: rem(14px);
      }
      &.v-list-item {
        &--active {
          .v-list-item-title {
            color: rgb(var(--v-theme-text));
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
