import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { config, paddle } from './config';
import { getAuthenticatedHeaders } from '@/lib/authentication';

const { apiBaseUrl, graphqlUrl } = config;

export const invalidatePaddlePrices = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', { property: 'monthPrice', value: 0 });
  commit('SET_STATE_PROPERTY', { property: 'annualyPrice', value: 0 });
};

export const fetchPaddlePrices = async ({ commit }) => {
  axios
    .get(`${apiBaseUrl}/paddle/prices`, await getAuthenticatedHeaders())
    .then((res) => {
      const {
        annual: { unit_price: annualPrice, trial_period: annualTrial },
        month: { unit_price: monthPrice, trial_period: monthTrial },
      } = res.data;
      commit('SET_STATE_PROPERTY', {
        property: 'monthPrice',
        // INFO: dividing with 100 because we receive prices in cents
        value: monthPrice.amount / 100,
      });
      commit('SET_STATE_PROPERTY', {
        property: 'monthCurrencyCode',
        value: monthPrice.currency_code,
      });
      commit('SET_STATE_PROPERTY', {
        property: 'trial_interval_month',
        value: monthTrial.interval,
      });
      commit('SET_STATE_PROPERTY', {
        property: 'trial_frequency_month',
        value: monthTrial.frequency,
      });

      commit('SET_STATE_PROPERTY', {
        property: 'annualyPrice',
        value: annualPrice.amount / 100,
      });
      commit('SET_STATE_PROPERTY', {
        property: 'annualyCurrencyCode',
        value: annualPrice.currency_code,
      });
      commit('SET_STATE_PROPERTY', {
        property: 'trial_interval_annual',
        value: annualTrial.interval,
      });
      commit('SET_STATE_PROPERTY', {
        property: 'trial_frequency_annual',
        value: annualTrial.frequency,
      });
    })
    .catch(Sentry.captureException);
};

//* Requires authentiation *//

export const getDiscountInfoForSubscriptionId = async ({ commit }, { subscription_id }) => {
  const query = `{
    getPaddleSubscriptionById(subscription_id:"${subscription_id}") {
      discount {
        id,
        starts_at,
        ends_at
      }
      }
    }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then(async (res) => {
      const discount = res.data.data?.getPaddleSubscriptionById?.discount;

      commit('SET_STATE_PROPERTY', {
        property: 'discount',
        value: discount,
      });

      // * Unauthenticated *//
      discount?.id &&
        axios.get(`${apiBaseUrl}/paddle/discounts/${discount?.id}`).then((response) => {
          if (response.data?.amount) {
            commit('SET_STATE_PROPERTY', {
              property: 'discountAmount',
              value: response.data.amount,
            });
          }
        });
    })
    .catch((err) => {
      Sentry.captureException(err);
      // console.log(err);
    });
};
// eslint-disable-next-line no-unused-vars
export const createPaddleCustomer = async ({ commit }, { email, uid }) => {
  const query = `{
    createCustomer(
      email: "${email}"
      uid: "${uid}"
    )
  }`;

  const response = await axios.post(graphqlUrl, { query }, await getAuthenticatedHeaders());
  const { errors, data } = response.data;
  if (errors) {
    Sentry.captureException(errors);
    // console.log(errors);
    return null;
  } else {
    // console.log('createCustomer data', data);
    return data.createCustomer;
  }
};

export const fetchSubscriptionMethodTransaction = async ({ commit }, { subscriptionId }) => {
  try {
    const query = `{
      getSubscriptionMethodTransaction(subscriptionId:"${subscriptionId}") {
          id
        }
      }`;
    const response = await axios.post(graphqlUrl, { query }, await getAuthenticatedHeaders());

    const responseData = response.data.data.getSubscriptionMethodTransaction;
    commit('SET_STATE_PROPERTY', {
      property: 'paddleTransactionId',
      value: responseData.id,
    });
  } catch (err) {
    console.error('Error fetching subscription method transaction:', err);
    // You may want to handle the error more robustly here, e.g., by committing an error state or returning a specific error message
    throw err; // Re-throw the error if you want the calling function to be aware of it
  }
};

export const cancelSubscription = async ({ dispatch }, { subscriptionId }) => {
  try {
    //eslint-disable-next-line no-undef
    await Paddle.Retain.initCancellationFlow({
      subscriptionId,
    });

    const query = `{
      cancelSubscription(subscriptionId:"${subscriptionId}")
    }`;
    const response = await axios.post(graphqlUrl, { query }, await getAuthenticatedHeaders());
    const { errors } = response.data;
    if (errors) throw new Error('Error canceling subscription', { cause: errors });
    await dispatch('fetchUserProfile');
  } catch (err) {
    console.error('Error canceling subscription:', err);
    throw err;
  }
};

export function checkIsSubscriptionCanceled(userProfile) {
  const scheduledChange = userProfile?.scheduled_change;
  const subscriptionCanceled = userProfile?.canceled_at;
  return scheduledChange?.action === 'cancel' || subscriptionCanceled;
}

export function checkIsSubscriptionCanceledActive(userProfile) {
  const subscriptionCanceled = userProfile?.canceled_at;
  return subscriptionCanceled;
}

function createPaddleEventCallback(dispatch) {
  return (data) => {
    dispatch('trackEvent', { event: data.name });

    if (data.name === 'checkout.completed') {
      localStorage.setItem('paddlePaymentInProgress', true);
    }
  };
}

export const initPaddle = async ({ dispatch }) => {
  // eslint-disable-next-line no-undef
  if (!Paddle) {
    return;
  }
  // eslint-disable-next-line no-undef
  if (Paddle.Initialized) {
    return;
  }
  // eslint-disable-next-line no-undef
  Paddle.Environment.set(paddle.environment);
  // eslint-disable-next-line no-undef
  Paddle.Initialize({
    token: paddle.token,
    eventCallback: createPaddleEventCallback(dispatch),
  });
};

export const updatePaddle = async ({ dispatch }, userDetails) => {
  dispatch('invalidatePaddlePrices');
  // eslint-disable-next-line no-undef
  if (!Paddle) {
    return;
  }
  // eslint-disable-next-line no-undef
  if (!Paddle.Initialized) {
    await dispatch('initPaddle');
  }
  // eslint-disable-next-line no-undef
  Paddle.Update({
    eventCallback: createPaddleEventCallback(dispatch),
    pwCustomer:
      userDetails && userDetails.paddleCustomerId ? { id: userDetails.paddleCustomerId } : null,
  });
};
