/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { config } from './config';


// filters[posts][publishedAt][$notNull]=true&pagination[posts][pageSize]=1&pagination[posts][page]=1
/**
 * Fetches the chat history list from the server.
 * @param {Object} context - The Vuex context object.
 */

const { cmsUrl } = config

export const getBlogPosts = async ({ commit }, payload) => {
  const { page, pageSize, search = '' } = payload;

  await axios.get(`${cmsUrl}/platform-guides`, {
    params: {
      pagination: {
        pageSize,
        page
      },
      filters: {
        Title: {
          $contains: search
        }
      },
      sort: 'createdAt:asc'
    }
  }).then((res) => {
      commit('SET_STATE_PROPERTY', {
        property: 'blog_list',
        value: res.data,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const resetBlogPosts = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', {
    property: 'blog_list',
    value: null,
  });
};

export const getBlogArticle = async ({ commit }, payload) => {
  const { id } = payload;
  await axios.get(`${cmsUrl}/platform-guides/${id}`)
    .then((response) => {
      commit('SET_STATE_PROPERTY', {
        property: 'blog_article',
        value: response.data,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const resetBlogArticle = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', {
    property: 'blog_article',
    value: null,
  });
};
