<template>
  <BaseModal v-model="modelValue" class="feedback-modal">
    <v-radio-group v-model="radios" class="feedback-modal__radios">
      <v-radio :label="t('chat.tips')" value="onboarding-modal"></v-radio>
      <v-radio :label="t('chat.feedback')" value="feedback-form"></v-radio>
    </v-radio-group>

    <v-slide-y-transition>
      <div v-if="isFormVisible">
        <div v-if="isError" class="feedback-form--error">Something went wrong</div>

        <v-form class="feedback-form" @submit.stop.prevent="submitForm">
          <div class="feedback-form__fields">
            <div class="feedback-form__row">
              <BaseTextarea
                class="feedback-form__field"
                :label="t('chat.shareFeedbackPlaceholder')"
                v-model="queryData.query"
                name="Query"
                dark />
            </div>
          </div>
          <div class="feedback-form__fields">
            <div class="feedback-form__row">
              <BaseCheckbox wrapper-class="w-100" v-model="queryData.notifyMe">
                <template #label>
                  <span>{{ t('chat.notifyMe') }}</span></template
                >
              </BaseCheckbox>
            </div>
          </div>
          <div class="feedback-form_fields">
            <div class="feedback-form__row">
              <BaseButton
                :loading="isLoading"
                class="feedback-form__btn"
                size="large"
                color="primary"
                type="submit"
                :disabled="!queryData.query"
                :max="mobile">
                {{ t('global.send') }}
              </BaseButton>
            </div>
          </div>
        </v-form>
      </div>
    </v-slide-y-transition>
  </BaseModal>
</template>
<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

import BaseModal from '@/components/BaseModal.vue';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseCheckbox from '@/components/form/BaseCheckbox.vue';
import { useUserProfile } from '@/composables/useUserProfile';

const store = useStore();
const { t } = useI18n();
const { mobile } = useDisplay();
const isLoading = ref(false);
const isError = ref(false);

const radios = ref('feedback-form');
const formActionUrl = 'https://formspree.io/f/mrbekrbb';

const { organizationNames, email } = useUserProfile();
const queryData = reactive({
  organizationValue: organizationNames.value,
  email: email.value,
  query: '',
  notifyMe: false,
});

const isFormVisible = computed(() => radios.value === 'feedback-form');
const modelValue = computed({
  get: () => store.state.feedbackModal,
  set: (val) => store.commit('SET_STATE_PROPERTY', { property: 'feedbackModal', value: val }),
});

const submitForm = async () => {
  try {
    isLoading.value = true;

    const response = await fetch(formActionUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(queryData),
    });

    if (response.ok) {
      resetStates();
    } else {
      isError.value = true;
    }
  } catch (error) {
    isError.value = true;
  } finally {
    isLoading.value = false;
  }
};
const resetStates = () => {
  modelValue.value = false;
  radios.value = 'feedback-form';
  queryData.value = organizationNames.value;
  queryData.email = email.value;
  queryData.query = '';
  queryData.notifyMe = false;
  isError.value = false;
  isLoading.value = false;
};
watch(
  () => queryData.query,
  () => {
    isError.value = false;
  }
);
watch(
  () => radios.value,
  (newVal) => {
    if (newVal === 'onboarding-modal') {
      resetStates();
      setTimeout(() => {
        store.commit('SET_STATE_PROPERTY', { property: 'onboardingModal', value: true });
      }, 200);
    }
  }
);
watch(
  () => modelValue.value,
  (newVal) => {
    if (!newVal) {
      resetStates();
    }
  }
);

watch(
  () => organizationNames.value,
  (newVal) => {
    queryData.organizationValue = newVal;
  }
);
watch(
  () => email.value,
  (newVal) => {
    queryData.email = newVal;
  }
);
</script>
<style lang="scss" scoped>
.feedback-modal {
  .feedback-modal__radios {
    margin-top: rem(32px);
  }
}
.feedback-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: rem(24px);
  min-height: rem(140px);
  &__fields {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: rem(2px);
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: rem(4px) rem(32px);
  }
  &__field {
    min-width: rem(220px);
    flex: 1 1 40%;
  }
  &__btn {
    max-width: none !important;
    width: 100%;
  }
}
.feedback-form--error {
  @include text12($color: red);
}
</style>
