import { computed } from 'vue';
import { useStore } from 'vuex';

export const useUserProfile = () => {
  const store = useStore();
  const userProfile = computed(() => store.state.userProfile);
  const organizationNames = computed(() =>
    store.state.organizations?.map((o) => o?.name).filter(Boolean)
  );
  const initials = computed(() => {
    const { firstName = '', email = '', lastName = '' } = store.state.userProfile ?? {};
    return [firstName || email, lastName]
      .filter(Boolean)
      .map((name) => name.charAt(0).toUpperCase())
      .join('');
  });
  const email = computed(() => store.state.userProfile?.email);
  const userId = computed(() => store.state.userProfile?.id);
  return { userProfile, initials, organizationNames, email, userId };
};
