<template>
  <SectionContent
    class="content__labels"
    :title="t('myProfile.personalInfo')"
    :text="t('myProfile.description')"
    h="3" />
  <v-form
    class="form"
    :class="{
      'form--mobile': mobile,
    }"
    @submit.prevent="saveChanges">
    <div class="form__row">
      <BaseField class="form__field" :label="t('fields.email')">
        <BaseInput
          :model-value="userProfile.email"
          type="email"
          name="Email"
          disabled
          :hide-details="false"
          dark />
      </BaseField>
    </div>
    <div class="form__row">
      <BaseField class="form__field" :label="t('fields.firstName')">
        <BaseInput
          v-model="userProfile.firstName"
          name="First name"
          :hide-details="false"
          dark
          :disabled="isUserLoading" />
      </BaseField>
      <BaseField class="form__field" :label="t('fields.lastName')">
        <BaseInput
          v-model="userProfile.lastName"
          name="Last name"
          :hide-details="false"
          dark
          :disabled="isUserLoading" />
      </BaseField>
    </div>
    <div class="form__row">
      <BaseField
        class="form__field"
        darker-label
        :label="`${t('fields.institution')} (${t('validations.required')})`">
        <BaseGroupedSelect
          :items="predefinedInstitutions"
          v-model="localInstitutionModel"
          :placeholder="t('global.select')"
          :rules="[rules.required()]"
          :loading="predefinedValuesLoading"
          :disabled="predefinedValuesLoading || isUserLoading"
          dark />

        <BaseInput
          v-if="isOtherInstitutionVisible"
          v-model="otherInstitution"
          name="otherInstitution"
          :hide-details="false"
          :autofocus="isOtherInstitutionVisible"
          :placeholder="t('fields.typeInstitution')"
          :rules="isOtherInstitutionVisible ? [rules.required()] : []"
          dark
          :disabled="isUserLoading" />
      </BaseField>

      <BaseField
        class="form__field"
        darker-label
        :label="`${t('fields.occupation')} (${t('validations.required')})`">
        <BaseGroupedSelect
          :items="predefinedOccupations"
          v-model="localOccupationModel"
          :placeholder="t('global.select')"
          :rules="[rules.required()]"
          :loading="predefinedValuesLoading"
          :disabled="predefinedValuesLoading || isUserLoading"
          dark />
        <BaseInput
          v-if="isOtherOccupationVisible"
          v-model="otherOccupation"
          name="otherOccupation"
          :hide-details="false"
          :autofocus="isOtherOccupationVisible"
          :disabled="isUserLoading"
          :placeholder="t('fields.typeOccupation')"
          :rules="isOtherOccupationVisible ? [rules.required()] : []"
          dark />
      </BaseField>
    </div>
    <div style="margin-top: 24px">
      <p class="form__label">
        {{ t('myProfile.languageText') }}
      </p>
    </div>
    <div class="form__row">
      <BaseField class="form__field lang-selector" :label="t('fields.language')">
        <BaseSelect
          v-model="userProfile.country"
          item-title="label"
          item-value="value"
          :items="langOptions"
          :placeholder="t('global.select')"
          :hide-details="false"
          :disabled="isUserLoading"
          dark />
      </BaseField>
      <BaseField class="form__field lang-selector" :label="t('fields.userLanguage')">
        <AutoComplete
          @focus="chatResponseLanguageModel = ''"
          @blur="handleChatLanguageBlur"
          v-model="chatResponseLanguageModel"
          menu-icon="mdi-chevron-down"
          :disabled="isUserLoading"
          :items="chatResponseLanguageOptions">
        </AutoComplete>
      </BaseField>
    </div>

    <BaseButton
      class="form__btn"
      size="large"
      color="primary"
      type="submit"
      :loading="isLoading"
      :disabled="isSubmitDisabled"
      disabled-tooltip
      :max="mobile">
      <v-fade-transition leave-absolute>
        {{ t('myProfile.actionText') }}
      </v-fade-transition>
    </BaseButton>
  </v-form>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useStore } from 'vuex';
import useRules from '@/composables/validations';
import BaseField from '@/components/form/BaseField.vue';
import BaseInput from '@/components/form/BaseInput.vue';
import BaseSelect from '@/components/form/BaseSelect.vue';
import BaseButton from '@/components/BaseButton.vue';
import { chatResponseLanguageOptions } from '@/features/Settings/utils';
import AutoComplete from '@/components/AutoComplete.vue';
import { userInterfaceLanguageOptions } from '@/composables/userInterfaceLanguageOptions';
import { CHAT_RESPONSE_LANGUAGE_BY_DEFAULT } from '@/features/Chat/utils';
import BaseGroupedSelect from '@/components/form/BaseGroupedSelect.vue';
import { debounce } from '@/utils';

const emit = defineEmits(['submit:userProfile']);
const { t } = useI18n();
const store = useStore();
const rules = useRules();
const { mobile } = useDisplay();
const userProfile = computed(() => store.state.userProfile);
const isUserLoading = computed(() => store.state.isUserLoading);

const prepareGroupMenuItems = (serverItems) => {
  const formattedItems =
    serverItems?.map((item) => ({
      mainItemId: item.label,
      mainItemLabel: item.label,
      subItems: item.values.map((subitem) => ({ name: subitem.value })),
    })) || [];
  formattedItems.push({ mainItemId: 'Other', mainItemLabel: 'Other' });
  return formattedItems;
};

const predefinedInstitutions = computed(() =>
  prepareGroupMenuItems(store.state.predefinedInstitutions)
);

const predefinedOccupations = computed(() =>
  prepareGroupMenuItems(store.state.predefinedOccupations)
);
const getFlatSubItems = (items) => {
  const flattedSubItems = (items?.map((a) => a.subItems) || []).flat().filter(Boolean);
  return flattedSubItems.map((item) => item.name);
};
const flatInstitutions = computed(() => {
  return getFlatSubItems(predefinedInstitutions.value) || [];
});
const flatOccupations = computed(() => {
  return getFlatSubItems(predefinedOccupations.value) || [];
});

const localOccupationModel = ref(
  !userProfile.value?.occupation
    ? ''
    : flatOccupations.value.includes(userProfile.value.occupation)
    ? userProfile.value.occupation
    : 'Other'
);
const localInstitutionModel = ref(
  !userProfile.value?.institution
    ? ''
    : flatInstitutions.value.includes(userProfile.value.institution)
    ? userProfile.value.institution
    : 'Other'
);
const otherOccupation = ref(
  !userProfile.value?.occupation ||
    (userProfile.value?.occupation && flatOccupations.value.includes(userProfile.value.occupation))
    ? ''
    : userProfile.value.occupation
);
const otherInstitution = ref(
  !userProfile.value?.institution ||
    (userProfile.value?.institution &&
      flatInstitutions.value.includes(userProfile.value.institution))
    ? ''
    : userProfile.value.institution
);
const isLoading = ref(false);
const langOptions = userInterfaceLanguageOptions();
const predefinedValuesLoading = ref(false);
const isOtherInstitutionVisible = computed(() => {
  return localInstitutionModel.value === 'Other';
});

const isOtherOccupationVisible = computed(() => {
  return localOccupationModel.value === 'Other';
});

const isValid = computed(() => {
  let occupationValid = false;
  let institutionValid = false;

  if (localInstitutionModel.value === 'Other' && otherInstitution.value) {
    institutionValid = true;
  } else if (localInstitutionModel.value !== 'Other' && localInstitutionModel.value) {
    institutionValid = true;
  }

  if (localOccupationModel.value === 'Other' && otherOccupation.value) {
    occupationValid = true;
  } else if (localOccupationModel.value !== 'Other' && localOccupationModel.value) {
    occupationValid = true;
  }
  return occupationValid && institutionValid;
});
const resetLoading = debounce(() => {
  isLoading.value = false;
}, 1000);

const isSubmitDisabled = computed(() => !isValid.value || predefinedValuesLoading.value);
// FIX : When user refresh the page in /userprofile page, it is not showing the default value of chatResponseLanguageModel
const chatResponseLanguageModel = ref(
  userProfile.value.userLanguage || CHAT_RESPONSE_LANGUAGE_BY_DEFAULT
);

const handleChatLanguageBlur = () => {
  if (!chatResponseLanguageModel.value) {
    chatResponseLanguageModel.value = userProfile.value.userLanguage;
  }
};

watch(
  () => [userProfile.value, predefinedInstitutions.value, predefinedOccupations.value],
  ([newUserProfile, newPredefinedInstitutions, newPredefinedOccupations]) => {
    if (newUserProfile && newPredefinedInstitutions && newPredefinedInstitutions.length) {
      if (!userProfile.value?.institution) {
        localInstitutionModel.value = '';
      } else {
        const institution = flatInstitutions.value.includes(userProfile.value.institution);
        localInstitutionModel.value = institution ? userProfile.value.institution : 'Other';
        otherInstitution.value = institution ? '' : userProfile.value.institution;
      }
    }
    if (newUserProfile && newPredefinedOccupations && newPredefinedOccupations.length) {
      if (!userProfile.value?.occupation) {
        localOccupationModel.value = '';
      } else {
        const occupation = flatOccupations.value.includes(userProfile.value.occupation);
        localOccupationModel.value = occupation ? userProfile.value.occupation : 'Other';
        otherOccupation.value = occupation ? '' : userProfile.value.occupation;
      }
    }
  },
  { deep: true }
);

onMounted(async () => {
  predefinedValuesLoading.value = true;
  await store.dispatch('fetchPredefinedInstitutionOccupation');
  predefinedValuesLoading.value = false;
});

const saveChanges = async () => {
  isLoading.value = true;
  if (!isValid.value) return;
  try {
    const newUser = JSON.parse(JSON.stringify(userProfile.value));
    if (userProfile.value?.institution !== localInstitutionModel.value) {
      if (localInstitutionModel.value === 'Other') {
        newUser.institution = otherInstitution.value;
      } else {
        newUser.institution = localInstitutionModel.value;
      }
    }
    if (userProfile.value?.occupation !== localOccupationModel.value) {
      if (localOccupationModel.value === 'Other') {
        newUser.occupation = otherOccupation.value;
      } else {
        newUser.occupation = localOccupationModel.value;
      }
    }
    newUser.userLanguage = chatResponseLanguageModel.value || CHAT_RESPONSE_LANGUAGE_BY_DEFAULT;
    await store.dispatch('updateUserProfile', newUser);
    const newLang = langOptions.value.find((el) => el.value === userProfile.value.country);
    if (newLang) {
      store.commit('SET_STATE_PROPERTY', { property: 'locale', value: newLang.value });
      localStorage.setItem('selectedLocale', newLang.value);
    }
    store.commit('SET_STATE_PROPERTY', {
      property: 'userProfile',
      value: newUser,
    });
    store.dispatch('trackEvent', {
      event: 'UserProfileUpdated',
      values: {
        content: {
          uid: newUser.uid,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          occupation: newUser.occupation,
          country: newUser?.country ?? CHAT_RESPONSE_LANGUAGE_BY_DEFAULT,
          institution: newUser.institution,
          userLanguage: newUser.userLanguage,
        },
      },
    });
    emit('submit:userProfile');
  } catch (error) {
    console.error('error', error);
  } finally {
    resetLoading();
  }
};
watch(
  () => localInstitutionModel.value,
  (newVal, prevValue) => {
    if (prevValue === 'Other' && newVal !== 'Other') {
      otherInstitution.value = '';
    }
  }
);
watch(
  () => localOccupationModel.value,
  (newVal, prevValue) => {
    if (prevValue === 'Other' && newVal !== 'Other') {
      otherOccupation.value = '';
    }
  }
);
</script>

<style lang="scss" scoped>
.content {
  &--right {
    display: flex;
    flex-direction: column;
    gap: rem(32px);
  }
  &__labels {
    margin-bottom: rem(24px);
    :deep() {
      .content__text {
        color: $color-text-lighten-2;
      }
    }
  }
  .link {
    @include text16($font-weight: 500);
    .loader {
      padding: 0;
      display: inline-block;
      margin-left: rem(6px);
    }
  }
  .plan {
    .link {
      color: $color-primary-lighten-2;
    }
    .upgrade {
      margin-left: auto;
    }
  }
  .contact {
    .contact-btn {
      max-width: rem(161px);
      font-weight: 500;
      border-radius: rem(8px);
      :deep() {
        .v-btn__content {
          color: $color-text-lighten-2;
        }
      }
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: rem(2px);
  align-items: stretch;
  &__row {
    display: flex;
    gap: rem(24px);
    & > * {
      flex: 1 1 40%;
    }
  }
  &__label {
    margin-bottom: rem(22px);
    @include text12($font-weight: 400, $color: $color-text-lighten-2);
  }
  .lang-selector {
    width: calc(50% + 6px);
    flex: unset;
  }
  &__btn {
    margin-top: rem(80px);
    max-width: rem(177px) !important;
  }
  :deep() {
    .base-field__label-text {
      color: $color-text-lighten-2 !important;
    }
    .v-field__input {
      color: $color-text-lighten-1;
      &::placeholder {
        color: $color-text-lighten-1 !important;
      }
    }
  }
  &--mobile & {
    &__row {
      flex-direction: column;
      gap: rem(2px);
    }
    &__btn {
      margin-top: rem(24px);
      max-width: unset !important;
    }
  }
  &--mobile {
    .lang-selector {
      width: 100%;
    }
  }
}
</style>
