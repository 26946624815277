/**
 * Create an interval for the given callback that can be interrupted with an abort signal.
 *
 * @param {function(): void} callback
 * @param {Number} interval
 * @returns {AbortController}
 */
export function createInterruptableInterval(callback, interval) {
  const controller = new AbortController();
  const { signal } = controller;

  const id = setInterval(() => {
    // If the signal was aborted, make sure the callback is not reached and the interval is cleared.
    if (signal?.aborted) {
      clearInterval(id);
      return;
    }

    // Invoke the callback.
    callback();
  }, interval);

  // If anything dispatches the 'abort' event on the signal, clear the interval.
  signal?.addEventListener(
    'abort',
    () => clearInterval(id),
    { once: true },
  );

  // Return the controller to pass on the capability to abort the interval.
  return controller;
}
