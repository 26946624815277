import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export const useSubmitQuestion = () => {
  const store = useStore();
  const router = useRouter();

  const submitQuestion = (value) => {
    store.dispatch('newChat');
    store.commit('SET_STATE_PROPERTY', { property: 'chatNewMessage', value });
    router.push({ name: 'Chat' });
  };

  return { submitQuestion };
};
