<template>
  <NotificationComponent :items="notificationItems" :mobile="mobile" />
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import NotificationComponent from '@/features/Header/components/NotificationComponent.vue';

const router = useRouter();
const { t } = useI18n();

const notificationItems = computed(() => [
  {
    slotName: 'first',
    text: t('myProfile.checkYourProfile'),
    action: () => router.push({ name: 'UserProfile' }),
  },
]);
defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
});
</script>
