<template>
  <v-img height="300" :src="data.img"> </v-img>
  <div class="slider-header">{{ data.title }}</div>
  <div v-for="item in data.content" :key="item.title" class="slide-section">
    <div class="d-flex align-items-center">
      <div v-if="item.icon" style="margin-right: 8px">
        <img :src="item.icon" :alt="iconAltText" />
      </div>
      <div class="slide-section__title" v-else style="margin-right: 8px">{{ item.title }}:</div>
      <div class="slide-section__description">
        {{ item.description }}
        <component
          :is="item?.additionalComponent"
          v-if="item?.additionalComponent"
          v-bind="item?.additionalComponentProps" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue';
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const { data } = toRefs(props);
</script>
<style lang="scss" scoped>
.slide-section {
  margin-top: rem(16px);
  animation: fadeText 0.5s;

  &__title {
    @include text14;
    white-space: nowrap;
    flex-shrink: 0;
    width: rem(130px);
  }
  &__description {
    @include text14($font-weight: 400);
  }
}
.icon {
  height: rem(12px);
  width: rem(12px);
}
.slider-header {
  @include text18;
  animation: fadeText 0.5s;
}
@keyframes fadeText {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
