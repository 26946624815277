<template>
  <div class="container">
    <OutputTemplatesTable
      :output-template-list-resource="outputTemplateListResource"
      @useTemplate="onUseOutputTemplate"
      @delete="onDeleteOutputTemplate"
      @rename="onOpenRenameOutputTemplateModal" />
    <div class="actions-container">
      <BaseButton variant="outlined" @click="emit('back')">{{
        t('answerFormatting.back')
      }}</BaseButton>
    </div>
  </div>
  <RenameTemplateModal
    v-model:name-input-model="nameInputModel"
    v-model:is-modal-open-model="renameModalOpenModel"
    :title="t('answerFormatting.renameTemplate')"
    :confirm-button-title="t('global.save')"
    @cancel="onRenameCancelled"
    @confirm="onRenameConfirmed" />
</template>

<script setup>
import BaseButton from '@/components/BaseButton.vue';
import OutputTemplatesTable from '@/features/OutputTemplate/components/OutputTemplatesTable.vue';
import RenameTemplateModal from '@/features/OutputTemplate/components/RenameTemplateModal.vue';
import { onMounted, ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const emit = defineEmits(['back', 'useOutputTemplate']);

const store = useStore();
const { t } = useI18n();

const outputTemplateListResource = computed(() => store.state.outputTemplateListResource);

const nameInputModel = ref('');
const outputTemplateToRename = ref(undefined);
const renameModalOpenModel = computed(() => outputTemplateToRename.value !== undefined);

const onUseOutputTemplate = (outputTemplateToUse) => {
  store.commit('SET_SELECTED_OUTPUT_TEMPLATE', outputTemplateToUse);
  emit('useOutputTemplate');
};

const onDeleteOutputTemplate = (outputTemplateToDelete) => {
  store.dispatch('deleteTemplate', outputTemplateToDelete);
};

const onOpenRenameOutputTemplateModal = (outputTemplate) => {
  outputTemplateToRename.value = outputTemplate;
  nameInputModel.value = outputTemplate.name;
};

const onRenameCancelled = () => {
  outputTemplateToRename.value = undefined;
  nameInputModel.value = '';
};

const onRenameConfirmed = () => {
  store.dispatch('saveOutputTemplate', {
    selectedOutputTemplate: outputTemplateToRename.value,
    name: nameInputModel.value,
  });
  onRenameCancelled();
};

onMounted(() => {
  if (!outputTemplateListResource.value) {
    store.dispatch('fetchOutputTemplateListResource');
  }
});
</script>

<style lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(8px);

  & .actions-container {
    margin-left: auto;
    margin-top: auto;
  }

  & > .v-table {
    min-height: 0;
  }
}
</style>
