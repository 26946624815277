<template>
  <v-tooltip v-if="ownedByType === OWNED_BY_TYPES.EVIDENCE_HUNT" text="EvidenceHunt">
    <template v-slot:activator="{ props }">
      <v-avatar v-bind="props" size="x-small">
        <inline-svg class="evidencehunt-logo" :src="LogoIcon" aria-label="logo" />
      </v-avatar>
    </template>
  </v-tooltip>
  <v-tooltip v-else-if="ownedByType === OWNED_BY_TYPES.USER" :text="t('answerFormatting.you')">
    <template v-slot:activator="{ props }">
      <UserAvatar v-bind="props" size="x-small" />
    </template>
  </v-tooltip>
  <v-tooltip v-else color="grey-lighten-2" :text="t('answerFormatting.unknown')">
    <template v-slot:activator="{ props }">
      <v-avatar v-bind="props" size="x-small">
        <span>?</span>
      </v-avatar>
    </template>
  </v-tooltip>
</template>

<script setup>
import LogoIcon from '@/assets/icons/logo-spear-short.svg';
import UserAvatar from '@/features/Header/components/UserAvatar.vue';
import { OWNED_BY_TYPES } from '@/utils/outputTemplate';
import { useI18n } from 'vue-i18n';

defineProps({
  ownedByType: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
</script>

<style lang="scss">
.evidencehunt-logo {
  width: rem(32px);
  height: rem(32px);
}
</style>
