import { createSHA256 } from 'hash-wasm';

/**
 * Create a hash for the contents of the given file.
 *
 * @param {File} file
 * @param {string} algorithm
 * @param {string} vendor
 *
 * @returns {Promise<string>}
 */
export async function calculateFileHash(file, algorithm = 'sha-256', vendor = 'hash-wasm') {
    const factories = {
    'hash-wasm': {
      'sha-256': createSHA256,
    },
  };
  const vendorFactories = factories[vendor];

  if (!vendorFactories) {
    throw new Error(`Vendor "${vendor}" is not supported.`);
  }

  const factory = factories[vendor][algorithm];

  if (!factory) {
    throw new Error(`Algorithm "${algorithm}" is not supported for vendor "${vendor}".`);
  }

  const reader = file.stream().getReader();
  const hasher = await factory();

  let done = false;

  while (!done) {
    // eslint-disable-next-line no-await-in-loop
    const { value, done: isDone } = await reader.read();

    if (value !== undefined) {
      hasher.update(value);
    }

    done = isDone;
  }

  return `$${vendor}$${algorithm}$${hasher.digest('hex')}`;
}
