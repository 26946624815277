import { getAuthenticatedHeaders } from '@/lib/authentication';
import { config } from '@/store/modules/config';

import { sortArrayByProperty } from '@/utils/sorting';

const { apiBaseUrl } = config;
export const fetchPredefinedInstitutionOccupation = async ({ commit }) => {
  const authHeaders = await getAuthenticatedHeaders();
  const response = await fetch(`${apiBaseUrl}/users/settings`, authHeaders);
  const data = await response.json();

  const { institutions = [], occupations = [] } = data;

  institutions.forEach((institution) => sortArrayByProperty(institution.values, 'value'));
  occupations.forEach((occupation) => sortArrayByProperty(occupation.values, 'value'));

  sortArrayByProperty(institutions, 'label');
  sortArrayByProperty(occupations, 'label');

  commit('SET_STATE_PROPERTY', {
    property: 'predefinedInstitutions',
    value: institutions,
  });
  commit('SET_STATE_PROPERTY', {
    property: 'predefinedOccupations',
    value: occupations,
  });
};
