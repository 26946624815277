<template>
  <v-slide-x-transition>
    <v-img height="300" :src="data.img"> </v-img>
  </v-slide-x-transition>
  <div class="slider-header">{{ data.title }}</div>
  <div
    v-for="item in data.content"
    :key="item.title"
    class="slide-section slide-section__description">
    <div class="d-flex align-center">
      {{ item.description }}
    </div>
    <div class="d-flex align-items-center mx-4">
      <v-icon
        size="small"
        style="margin-right: 4px"
        :icon="item.isGood ? 'mdi-check' : 'mdi-close'"
        :color="item.isGood ? 'green' : 'red'" />
      {{ item.example }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue';
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const { data } = toRefs(props);
</script>
<style lang="scss" scoped>
.slide-section {
  animation: fadeText 0.5s;

  &__description {
    margin-top: rem(16px);
    @include text14($font-weight: 400);
  }
}
.slider-header {
  @include text18;
  animation: fadeText 0.5s;
  margin-top: rem(16px);
}
@keyframes fadeText {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
