import disciplines from '@/lib/variables';

const lut = disciplines.reduce((collect, doc) => {
  doc.categories.forEach((d) => (collect[d.id] = d.label));
  return collect;
}, {});

export const formatFirebaseCategory = (values) => values.map((value) => lut[value]).join(', ');

export const formatPubmedCounter = (labelValue) => {
  const foo =
    Math.abs(Number(labelValue)) >= 1.0e9
      ? `${Math.abs(Number(labelValue)) / 1.0e9}B`
      : Math.abs(Number(labelValue)) >= 1.0e6
      ? `${Math.abs(Number(labelValue)) / 1.0e6}M`
      : Math.abs(Number(labelValue)) >= 1.0e3
      ? `${Math.abs(Number(labelValue)) / 1.0e3}K`
      : Math.abs(Number(labelValue));
  return parseFloat(foo).toPrecision(2) + foo.replace(/[^B|M|K]/g, '');
};

export const formatFirebaseDiscipline = (value) => value.replaceAll('_', ' ').replaceAll(',', ', ');

export const convertMlType = (value) => {
  const lookup = {
    randomized_trial: 'Randomized Trial',
    meta_analysis: 'Meta Analysis',
    systematic_review: 'Systematic Review',
    human_intervention: 'Clinical Intervention',
    rodent: 'Rodent',
    protocol: 'Protocol',
    misc: 'Other',
  };

  return lookup[value];
};

export const formatFirebaseDate = (value) => {
  if (value) {
    // return value;
    const t = new Date(Date.UTC(1970, 0, 1));
    t.setUTCSeconds(value);
    return t.toLocaleDateString();
  } else {
    return '';
  }
};

export const formatDate = (value) => {
  if (value) {
    return value.toDate().toLocaleDateString();
  } else {
    return '';
  }
};

export const formatTitleShort = (value) => {
  if (value) {
    return value.length > 70 ? `${value.substring(0, 70)}...` : value;
  } else {
    return '';
  }
};

export function debounce(fn, delay = 0) {
  let timeoutID = null;
  return function (...args) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => fn.apply(this, args), delay);
  };
}
export function throttle(func, delay = 0) {
  let prev = 0;
  // eslint-disable-next-line consistent-return
  return (...args) => {
    const now = new Date().getTime();
    if (now - prev > delay) {
      prev = now;
      return func(...args);
    }
  };
}

export async function wait(delay) {
  await new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function capitalize(payload, global = true) {
  if (!global) {
    return payload.toLowerCase().replace(/(?:^|\s|[-])\S/, (match) => match.toUpperCase());
  }
  return payload.toLowerCase().replace(/(?:^|\s|[-])\S/g, (match) => match.toUpperCase());
}

export const getMonthName = (idx, locale = 'en') => {
  const date = new Date();
  date.setMonth(idx);

  const monthName = new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);
  return monthName;
};

export const formatDateFixed = ({ value, locale = 'en', alt = false }) => {
  const date = new Date(value);

  const monthName = new Intl.DateTimeFormat(locale, { month: 'short' }).format(date);
  const day = date.getDate();
  const year = date.getFullYear();

  if (alt) return `${day}-${monthName.toLowerCase()}-${year}`;
  return `${day} ${monthName} ${year}`;
};

export function countMonthsUntilNow({ year, month }) {
  const currentDate = new Date();
  const targetDate = new Date(year, month - 1);

  const monthsDifference =
    (currentDate.getFullYear() - targetDate.getFullYear()) * 12 +
    currentDate.getMonth() -
    targetDate.getMonth();

  return monthsDifference;
}

export function handleNavIntersection(navEl) {
  const headerEl = document.querySelector('header');
  const footerEl = document.querySelector('footer');

  if (!headerEl || !footerEl || !navEl) return;
  const headerRects = headerEl.getBoundingClientRect();
  const footerRects = footerEl.getBoundingClientRect();

  // top margin
  if (headerRects.bottom < 0) {
    navEl.style.marginTop = `${-headerRects.height}px`;
  } else {
    navEl.style.marginTop = `${headerRects.top}px`;
  }

  // bottom margin
  if (footerRects.top > window.innerHeight) {
    navEl.style.marginBottom = '0';
  } else {
    navEl.style.marginBottom = `${window.innerHeight - footerRects.top}px`;
  }
}

export const highlightSearch = (needle, source) => {
  if (!needle.trim() || !source) {
    return source;
  }
  const escapedNeedle = needle.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  return source.replace(new RegExp(escapedNeedle, 'gi'), (match) => `<mark>${match}</mark>`);
};

// eslint-disable-next-line no-bitwise
export const isAvailableBitwise = (listBitwise, index) => (listBitwise & (1 << index)) !== 0;

export function getNonEmptyObject(obj) {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
}

export const getRouteName = (route, lowerCase = false) => {
  const name = route.name || route.path.split('/').at(-1);
  return (lowerCase ? name?.toLowerCase() : name) || null;
};

export const getYearMonths = (val) => {
  if (!val) return null;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // +1 to convert month index to duration:
  return currentMonth + (currentYear - val) * 12;
};

export const changeQuotes = (payload, desired = '"') => payload.replace(/['"`"]/g, desired);

export const cutString = (payload, length = 255) => {
  if (!payload) return '';
  if (payload.length <= length) return payload;
  return `${payload.slice(0, length - 3)}...`;
};

const customPropNames = {
  ml_n: 'sample_size',
};

export const getCustomPropName = (prop) => customPropNames[prop] || prop;

export const convertToCustomNames = (
  val,
  customPropName = getCustomPropName,
  customValueName = getCustomPropName
) => {
  if (typeof val !== 'object' || val === null) {
    return customValueName?.(val) || val;
  }
  if (Array.isArray(val)) {
    return val.map((item) => convertToCustomNames(item, customPropName));
  }
  const renamedval = {};

  for (const key of Object.keys(val)) {
    const newKey = customPropName(key);
    renamedval[newKey] = convertToCustomNames(val[key], customPropName);
  }

  return renamedval;
};

export const scrollToSection = (slug) => {
  const section = document.getElementById(slug);

  if (section) {
    const top = section.getBoundingClientRect().top + window.scrollY - 50;
    window.scrollTo({ top, behavior: 'smooth' });
  }
};
