<template>
  <RecentOutputTemplateMenu>
    <template v-slot:activator="{ activatorProps, isLoading }">
      <v-select
        v-model="selectedItemModel"
        v-bind="activatorProps"
        :placeholder="t('answerFormatting.selectTemplate')"
        :disabled="isLoading"
        :loading="isLoading"
        :readonly="true"
        :hide-details="true"
        density="compact"
        clearable
        persistent-clear
        @click:clear.stop="emit('clearSelectedTemplate')">
        <template v-slot:selection="{ item }">
          <div class="selected-item">
            <OutputTemplateCreator :owned-by-type="item.raw.ownedByType" />
            <span class="text-truncate">{{ item.raw.name }}</span>
          </div>
        </template>
      </v-select>
    </template>
  </RecentOutputTemplateMenu>
</template>

<script setup>
import OutputTemplateCreator from '@/features/OutputTemplate/components/OutputTemplateCreator.vue';
import RecentOutputTemplateMenu from '@/features/OutputTemplate/components/RecentOutputTemplateMenu';
import { computed, onMounted, ref } from 'vue';
import * as Sentry from '@sentry/browser';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { t } = useI18n();

const emit = defineEmits(['clearSelectedTemplate']);

const selectedItemModel = computed({
  get: () => store.getters.getSelectedOutputTemplate,
  set: () => {
    // Do nothing. The state of the select is handled from the parent component
  },
});
</script>

<style lang="scss">
.selected-item {
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: rem(8px);
}
</style>
