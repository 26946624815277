<template>
  <v-autocomplete
    class="base-select base-select--light text-secondary"
    bg-color="bg"
    rounded="lg"
    variant="outlined"
    auto-select-first
    hide-details
    flat
    width="100%"
    active
    item-color="primary"
    @click.stop
    location="top"
    elevation="0"
    item-title="label"
    item-value="value"
    outlined
    density="comfortable"></v-autocomplete>
</template>

<script setup></script>

<style lang="scss" scoped>
.base-select {
  :deep() {
    .v-field {
      height: rem(44px);
      background-color: rgb(var(--v-theme-bg-darken-1)) !important;

      .v-field__outline {
        color: $color-secondary;
        --v-field-border-opacity: 0.7;
      }
      &:hover {
        .v-field__outline {
          --v-field-border-opacity: 1;
        }
      }

      &:focus-within {
        outline-width: 2px;
      }
    }
    .v-field__field {
      .v-label {
        color: $color-text;
      }
    }
    .v-field__input {
      font-size: rem(16px);
      font-weight: 500;
      color: $color-text;
    }
    .v-field__append-inner {
      margin-right: -8px;
      color: rgb(var(--v-theme-on-bg-darken-1)) !important;
      font-weight: 500;
    }
  }
}
</style>
