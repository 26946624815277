<template>
  <v-btn density="comfortable" :icon="icon" :disabled="disabled" variant="text" @click="onClick" />
</template>

<script setup>
defineProps({
  icon: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

const onClick = () => {
  emit('click');
};
</script>
