<template>
  <div class="batch-progress-chip" v-if="isBatchUploading">
    <router-link class="link" :to="`/source/${uploadingSourceId}`">
      <v-chip
        :model-value="true"
        :class="{ 'chip-with-shimmer': progress < 100, 'chip-basic': true }">
        {{
          t('sources.uploadProgressChip', {
            processed,
            total: totalFiles,
            progress,
            sourceName,
          })
        }}
        <template #prepend>
          <v-icon icon="mdi-progress-upload" style="margin-right: 6px; margin-left: -6px"></v-icon>
        </template>
      </v-chip>
    </router-link>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();
const store = useStore();
const files = computed(() => Array.from(store.state.filesToUpload.values()));
const totalFiles = computed(() => files.value.length);
const totalBytes = computed(() =>
  files.value.reduce((carry, file) => carry + (file?.local?.size ?? 0), 0)
);
const bytesSent = computed(() =>
  files.value.reduce((carry, file) => carry + (file?.bytesSent ?? 0), 0)
);
const progress = computed(() => {
  const _progress = totalBytes.value ? (bytesSent.value / totalBytes.value) * 100 : 0;
  return _progress.toFixed(_progress < 100 ? 1 : 0);
});
const uploaded = computed(() =>
  files.value.filter((file) => file?.local?.size === file?.bytesSent)
);
const failedFiles = computed(() => store.state.failedFiles);
const processed = computed(
  () => new Set([...uploaded.value.map((a) => a.remote.id), ...failedFiles.value]).size
);
const sourceBusyWithUploading = computed(() => store.state.sourceBusyWithUploading);
const uploadingSourceId = computed(() => sourceBusyWithUploading.value?.id);
const isBatchUploading = computed(() => !!sourceBusyWithUploading.value);
const sourceName = computed(() => sourceBusyWithUploading.value?.name);

const preventExit = (event) => {
  if (isBatchUploading.value) {
    event.preventDefault();
    event.returnValue = '';
  }
};
onMounted(() => {
  window.addEventListener('beforeunload', preventExit);
});
onUnmounted(() => {
  window.removeEventListener('beforeunload', preventExit);
});
</script>

<style lang="scss" scoped>
.batch-progress-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 'fit-content';
  position: sticky;
  top: 62px;
  /**Less than top navigation */
  z-index: 1003;
}
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.chip-basic {
  background-color: $color-primary-lighten-1;
  color: white;
}
.chip-with-shimmer {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.chip-with-shimmer::after {
  content: '';
  position: absolute;
  right: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
  transform: translateX(-100%);
  animation: shimmer 1s infinite;
}
.link {
  text-decoration: none;
  color: inherit;
}
</style>
