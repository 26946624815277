<template>
  <div class="contact-us">
    <div class="page-container contact-us__container">
      
      <div class="contact-us__content">
        <p class="title">{{ t('settings.contact.addSourcesTitle') }}</p>
        <h3>{{ t('settings.contact.addSourcesSubtitle') }} </h3>
        <div class="subtitle">
          <p :class="{ 'subtitle-mobile': mobile } ">
            {{ t('settings.contact.addSourcesSelectCategory') }}
          </p>
        </div>
        <div class="contact-us__organization">
          <div
            v-for="item in organizationList"
            :key="item.icon"
            class="contact-us__organization--item"
            :class="{ active: queryData.organization_type === item.title }"
            @click="queryData.organization_type = item.title">
            <inline-svg
              :src="require(`@/assets/icons/solutions/${item.icon}.svg`)"
              width="64"
              height="64"></inline-svg>
            <span>{{ item.title }}</span>
          </div>
        </div>
        <v-form
          ref="contactForm"
          class="contact-form"
          v-model="isValid"
          :action="formActionUrl"
          method="POST"
          @submit.prevent="submitForm">
          <div class="contact-form__fields">
            <span class="contact-form__title">{{ t('settings.contact.addSourcesPersonalInfo') }}</span>
            <div class="contact-form__row _grid">
              <BaseInput
                class="contact-form__field"
                placeholder="First name*"
                v-model="queryData.first_name"
                :rules="[rules.required()]"
                name="First name" />
              <BaseInput
                class="contact-form__field"
                placeholder="Last name*"
                v-model="queryData.second_name"
                :rules="[rules.required()]"
                name="Last name" />
              <BaseInput
                class="contact-form__field"
                placeholder="Email*"
                v-model="queryData.email"
                :rules="[rules.required(), rules.email()]"
                type="email"
                name="Email" />
              <BaseInput
                class="contact-form__field"
                placeholder="Phone"
                v-model="queryData.phone"
                name="Phone" />
            </div>
            <span class="contact-form__title">Company information</span>
            <div class="contact-form__row _grid">
              <BaseInput
                class="contact-form__field"
                placeholder="Job title"
                v-model="queryData.job_title"
                name="Job title" />
              <BaseInput
                class="contact-form__field"
                placeholder="Company*"
                v-model="queryData.company"
                :rules="[rules.required()]"
                name="Company" />
              <BaseInput
                class="contact-form__field"
                placeholder="Country (HQ Address)"
                v-model="queryData.country"
                name="Country" />
            </div>
            <span class="contact-form__title">Details</span>
            <div class="contact-form__row">
              <BaseTextarea
                class="contact-form__field"
                placeholder="Please give us some information about your requirements"
                v-model="queryData.query"
                name="Query" />
            </div>
          </div>
          <span class="contact-form__title">* Required fields</span>
          <BaseButton
            class="contact-form__btn"
            size="large"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :max="mobile">
            Send a message
          </BaseButton>
        </v-form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useDisplay } from 'vuetify';
import { toast } from 'vue3-toastify';
import useRules from '@/composables/validations';
import { useI18n } from 'vue-i18n';
import BaseInput from '@/components/form/BaseInput.vue';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import BaseButton from '@/components/BaseButton.vue';
const rules = useRules();
const store = useStore();
const { mobile } = useDisplay();
const { t } = useI18n();
const organizationList = [
  {
    title: 'Healthcare',
    icon: 'healthcare',
  },
  {
    title: 'Life Sciences',
    icon: 'sciences',
  },
  {
    title: 'Academic',
    icon: 'academic',
  },
  {
    title: 'Other',
    icon: 'other',
  },
];

const queryData = reactive({
  organization_type: '',
  first_name: '',
  second_name: '',
  email: '',
  phone: '',
  job_title: '',
  company: '',
  country: '',
  query: '',
});

const title = ref('Contact us, and we tailor a package to\nsuit your needs');
const contactForm = ref(null);

const isPro = computed(() => store.getters.isPro);
const formActionUrl = computed(
  () => `https://formspree.io/f/${isPro.value ? 'mdoqzbwv' : 'mzbndeke'}`
);

const isValid = ref(false);

const submitForm = async (event) => {
  const data = new FormData(event.target);
  fetch(event.target.action, {
    method: contactForm.value.method,
    headers: { Accept: 'application/json' },
    body: data,
  })
    .then((response) => {
      if (response.ok) {
        toast.success('Your request was successfully sent.', {
          autoClose: 5000,
        });
        Object.keys(queryData).forEach((key) => {
          queryData[key] = '';
        });

        store.commit('SET_STATE_PROPERTY', {
          property: 'contactModalFull',
          value: false,
        });
      } else {
        toast.error('Failed to send message. Please try again.', {
          autoClose: 5000,
        });
      }
    })
    .catch(() => {
      toast.error('Something went wrong.', {
        autoClose: 5000,
      });
    });
};
</script>

<style lang="scss" scoped>
.contact-us {
  position: relative;
  z-index: 1;
  padding-bottom: 40px;

  .section-title {
    max-width: 530px;
  }

  &__content {
    font-size: 16px;
    line-height: 24px;
    color: #384060;
    width: 100%;
    font-weight: 500;

    .title {
      margin-top:20px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      color: #0f1c37;
      margin-bottom: 7px;
    }
    .subtitle {
     margin-top:30px
    }
  }

  &__organization {
    display: flex;
    align-items: center;
    gap: 17px;
    margin: 30px 0;
    flex-wrap: wrap;

    &--item {
      width: 174px;
      height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: #fff;
      border: 1px solid #dfe7f5;
      border-radius: 16px;
      color: #0563ff;
      cursor: pointer;

      &.active {
        background-color: #0563ff;
        color: #fff;

        span {
          color: #fff;
        }
      }

      span {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        color: #000000;
      }
    }
  }

  @include responsive(1050px) {
    &__container {
      flex-direction: column;
    }
  }

  @include responsive {
    &__container {
      padding-inline: 0;
    }

    &__content {
      padding-top: 10px;

      .subtitle-mobile {
        font-size: 14px;
      }
    }

    &__organization {
      display: grid;
      grid-template-columns: minmax(0, 174px) minmax(0, 174px);
      grid-template-rows: auto;
      grid-column-gap: 14px;
      grid-row-gap: 12px;

      &--item {
        height: 110px;
        width: 100%;

        svg {
          max-height: 40px;
        }

        span {
          font-size: 15px;
        }
      }
    }
  }
}
.contact-modal-full .contact-form__btn {
    width: 30%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: rem(24px);
  min-height: rem(440px);
  &__fields {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: rem(2px);
  }

  &__title {
    margin-bottom: 10px;
  }
  &__row {
    &._grid {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-rows: auto;
      margin-bottom: 45px;
      grid-row-gap: 30px;
      grid-column-gap: 20px;

      @include responsive {
        grid-template-columns: 1fr;
      }
    }
  }
  &__field {
    min-width: rem(220px);
    flex: 1 1 40%;
    color: #686868 !important;
  }
}
</style>
