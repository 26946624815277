/* eslint-disable import/prefer-default-export */
import XIcon from '@/assets/icons/social-media/x-icon.svg';
import YoutubeIcon from '@/assets/icons/social-media/youtube.svg';
import LinkedinIcon from '@/assets/icons/social-media/linkedin.svg';
import InstagramIcon from '@/assets/icons/social-media/instagram.svg';

export const socialLinks = [
  {
    id: 'x',
    name: 'X',
    icon: XIcon,
    route: 'https://x.com/Evidence_Hunt',
  },
  {
    id: 'youtube',
    name: 'Youtube',
    icon: YoutubeIcon,
    route: 'https://www.youtube.com/@EvidenceHunt',
  },
  {
    id: 'linkedin',
    name: 'LinkedIn',
    icon: LinkedinIcon,
    route: 'https://www.linkedin.com/company/evidencehunt/',
  },
  {
    id: 'instagram',
    name: 'Instagram',
    icon: InstagramIcon,
    route: 'https://www.instagram.com/evidencehuntai/',
  },
];
