<template>
  <div class="blured-circle" :style="{ width: contentWidth, height: contentHeight }">

  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useDisplay } from 'vuetify'
const props = defineProps({
  width: {
    type: String,
    required: true
  },
  height: {
    type: String,
    required: true
  },
  bottom: {
    type: String,
    default: 'unset'
  },
  left: {
    type: String,
    default: 'unset'
  },
  right: {
    type: String,
    default: 'unset'
  },
  top: {
    type: String,
    default: 'unset'
  },
  tranformX: {
    type: String,
    default: '0'
  },
  tranformY: {
    type: String,
    default: '0'
  },
  opacity: {
    type: Number,
    default: 0.5
  }

})

const { mobile } = useDisplay()

const contentWidth = computed(() => mobile.value ? `${props.width.replace('px', '') / 2}px` : props.width)
const contentHeight = computed(() => mobile.value ? `${props.height.replace('px', '') / 2}px` : props.height)

const background = `rgba(4, 100, 255, ${props.opacity})`
</script>

<style lang="scss" scoped>
.blured-circle {
  position: absolute;
  bottom: v-bind(bottom);
  right: v-bind(right);
  left: v-bind(left);
  top: v-bind(top);
  border-radius: 50%;
  background: v-bind(background);
  z-index: -1;
  filter: blur(180px);
  transform: translateX(v-bind(tranformX)) translateY(v-bind(tranformY));
}
</style>
