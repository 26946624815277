<template>
  <RouterLink class="link" :to="{ name: 'UserProfile' }">
    <v-card-text :class="{ 'disable-card-padding': disableContainerPadding }">
      <div :class="containerClass">
        <UserAvatar :size="avatarSize" />
        <div
          v-if="displayName && hasName"
          :class="{
            'user-menu-username-container': true,
            'align-center': alignCenter,
            'gap-between-avatar': !disableGap,
          }">
          <v-list-item-title class="user-menu-username">{{ displayName }}</v-list-item-title>
          <v-icon v-if="!hidePencil" size="small" icon="mdi-pencil-outline"></v-icon>
        </div>
        <slot name="append" />

        <div
          v-if="!isPayingUser && !isSuperAdmin && !hidePaymentButton"
          class="user-menu-paying-button">
          <UpgradeButton />
        </div>
      </div>
    </v-card-text>
  </RouterLink>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import UpgradeButton from '@/features/Header/components/UpgradeButton.vue';
import UserAvatar from '@/features/Header/components/UserAvatar.vue';

const props = defineProps({
  avatarSize: {
    type: String,
    default: 'default',
    validator: (value) => ['small', 'default'].includes(value),
  },
  alignCenter: {
    type: Boolean,
    default: true,
  },
  hidePaymentButton: {
    type: Boolean,
    default: false,
  },
  disableGap: {
    type: Boolean,
    default: true,
  },
  hidePencil: {
    type: Boolean,
    default: false,
  },
  disableContainerPadding: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const isSuperAdmin = computed(() => store.getters.isSuperAdmin);
const displayName = computed(() => store.getters.displayName);
const hasName = computed(
  () => displayName.value && displayName.value !== store.state.userProfile?.email
);
const isPayingUser = computed(() => store.getters.isPaying);

const containerClass = computed(() => (props.alignCenter ? 'mx-auto text-center' : ''));
</script>

<style lang="scss" scoped>
.user-menu-username {
  font-weight: 500;
}

.link {
  text-decoration: none;
  color: inherit;
}

.user-menu-username-container {
  display: flex;
}
.gap-between-avatar {
  margin-top: 8px;
}
.align-center {
  justify-content: center;
}
.user-menu-paying-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 10px;
}
.disable-card-padding {
  padding: 0;
}
.avatar-text {
  @include text18($font-weight: 400);
}
</style>
