<template>
  <BaseModal
    :title="t('answerFormatting.title')"
    v-model="internalModalModel"
    :max-width="1000"
    :height="650">
    <div v-if="modalActivePage === OUTPUT_TEMPLATE_MODAL_PAGES.ANSWER_FORMATTER" class="container">
      <div class="description description__flex">
        <span>{{ t('answerFormatting.description_1_1') }}</span>
        <HelpIcon :description="t('answerFormatting.customTemplateHelp')" />
      </div>
      <div class="active-template-toolbar">
        <RecentOutputTemplateSelect
          @seeMoreTemplates="onNavigateToAllOutputTemplatesPage"
          @selectTemplate="onSelectTemplate"
          @clearSelectedTemplate="onClearSelectedTemplate" />
        <div class="actions">
          <OutputTemplateToolbarAction
            v-if="!shouldCreateNewOutputTemplate"
            icon="mdi-rename"
            @click="onOpenRenameOutputTemplateModal" />
          <OutputTemplateToolbarAction
            icon="mdi-content-save"
            :disabled="!canSaveAnswerFormat"
            @click="onSaveAnswerFormat" />
        </div>
        <KeepFormatButton />
      </div>
      <TokenInformativeBlock
        :hide-progressbar="true"
        :is-token-limit-exceeded="isTokenLimitExceeded"
        :current-token-percentage="currentTokenPercentage"
        :informative-message="t('chat.formatTokenLimitExceeded')" />
      <BaseTextarea
        rows="15"
        :disable-auto-grow="true"
        v-model="textAreaModel"
        class="textarea-bg" />
      <div :class="buttonContainerClasses">
        <div :class="leftButtonsContainerClasses">
          <BaseButton v-if="isChatAnswerFormatAlreadySet" color="secondary" @click="resetFormat">{{
            t('answerFormatting.resetFormat')
          }}</BaseButton>
        </div>
        <div :class="rightButtonsContainerClasses">
          <BaseButton
            variant="outlined"
            v-if="!isRegenerateInvisible"
            @click="regenerateAnswer"
            :disabled="!isRegenerateEnabled"
            >{{ t('answerFormatting.regenerateAnswerWithFormat') }}</BaseButton
          >
          <BaseButton @click="setAnswerFormattingText" :disabled="isSetFormatDisabled">{{
            t('answerFormatting.setFormat')
          }}</BaseButton>
        </div>
      </div>
      <RenameTemplateModal
        v-model:name-input-model="nameInputModel"
        v-model:is-modal-open-model="renameModalOpenModel"
        :title="renameModalTitle"
        :confirm-button-title="renameModalConfirmButtonTitle"
        @cancel="onRenameCancelled"
        @confirm="onRenameConfirmed" />
    </div>
    <OutputTemplatesModalBody
      v-else-if="modalActivePage === OUTPUT_TEMPLATE_MODAL_PAGES.ALL_TEMPLATES"
      @back="onNavigateToAnswerFormattingPage"
      @useOutputTemplate="onNavigateToAnswerFormattingPage" />
  </BaseModal>
</template>

<script setup>
import BaseButton from '@/components/BaseButton.vue';
import BaseModal from '@/components/BaseModal';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import HelpIcon from '@/components/HelpIcon.vue';
import { useTokenizer } from '@/composables/tokens';
import TokenInformativeBlock from '@/features/Chat/components/TokenInformativeBlock.vue';
import KeepFormatButton from '@/features/OutputTemplate/components/KeepFormatButton.vue';
import OutputTemplatesModalBody from '@/features/OutputTemplate/components/OutputTemplatesModalBody.vue';
import OutputTemplateToolbarAction from '@/features/OutputTemplate/components/OutputTemplateToolbarAction.vue';
import RecentOutputTemplateSelect from '@/features/OutputTemplate/components/RecentOutputTemplateSelect.vue';
import RenameTemplateModal from '@/features/OutputTemplate/components/RenameTemplateModal.vue';
import { OUTPUT_TEMPLATE_MODAL_PAGES } from '@/utils/outputTemplate';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const MAX_TOKEN_LIMIT = 1500;

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const { countTokens, currentTokenPercentage, isTokenLimitExceeded } = useTokenizer();

const modalActivePage = computed(() => store.state.activeOutputTemplateModalContent);
const onNavigateToAllOutputTemplatesPage = () => store.commit('NAVIGATE_TO_ALL_TEMPLATES_PAGE');
const onNavigateToAnswerFormattingPage = () => store.commit('NAVIGATE_TO_ANSWER_FORMATTING_PAGE');

const isChatAnswerFormatAlreadySet = computed(() => store.getters.isAnswerFormatActive);
const chatSessionId = computed(() => store.state.chatSessionId);
const textAreaModel = computed({
  get: () => store.state.answerFormat,
  set: (newPrompt) => store.commit('UPDATE_ANSWER_FORMAT', newPrompt),
});
const canSaveAnswerFormat = computed(() => store.getters.canSaveAnswerFormat);
const shouldCreateNewOutputTemplate = computed(() => store.getters.shouldCreateNewOutputTemplate);

const internalModalModel = computed({
  get: () => store.getters.getChatOutputTemplateModal,
  set: (val) => {
    store.commit('SET_STATE_PROPERTY', { property: 'chatOutputTemplateModal', value: val });
  },
});

const nameInputModel = ref('');
const renameModalOpenModel = ref(false);

const renameModalTitle = computed(() =>
  shouldCreateNewOutputTemplate.value
    ? t('answerFormatting.nameTemplate')
    : t('answerFormatting.renameTemplate')
);

const renameModalConfirmButtonTitle = computed(() =>
  shouldCreateNewOutputTemplate.value ? t('global.create') : t('global.save')
);

const onSelectTemplate = (selectedOutputTemplate) => {
  store.commit('SET_SELECTED_OUTPUT_TEMPLATE', selectedOutputTemplate);
};
const onClearSelectedTemplate = () => store.commit('CLEAR_SELECTED_TEMPLATE');

const onRenameCancelled = () => {
  renameModalOpenModel.value = false;
  nameInputModel.value = '';
};

const onRenameConfirmed = () => {
  store.dispatch('saveOutputTemplate', {
    selectedOutputTemplate: store.state.selectedOutputTemplateResource,
    prompt: textAreaModel.value,
    name: nameInputModel.value,
  });
  renameModalOpenModel.value = false;
};

const onOpenRenameOutputTemplateModal = () => {
  renameModalOpenModel.value = true;
  nameInputModel.value = store.state.selectedOutputTemplateResource?.name || '';
};

const onSaveAnswerFormat = () => {
  if (shouldCreateNewOutputTemplate.value) {
    renameModalOpenModel.value = true;
    const currentName = store.state.selectedOutputTemplateResource?.name;
    nameInputModel.value = currentName ? `Copy of ${currentName}` : '';
  } else {
    store.dispatch('saveOutputTemplate', {
      selectedOutputTemplate: store.state.selectedOutputTemplateResource,
      prompt: textAreaModel.value,
    });
  }
};

const isSetFormatDisabled = computed(() => !store.getters.isAnswerFormatValid);

const userProfile = computed(() => store.state.userProfile);
const chatNewMessage = computed(() => store.state.chatNewMessage);

const isEnglishUser = computed(() => store.getters.isEnglishUser);

const commonClasses = computed(() => ({
  actions_container__engels_mobile: isEnglishUser.value,
  actions_container__nederlands_mobile: !isEnglishUser.value,
}));

const buttonContainerClasses = computed(() => ({
  actions_container: true,
  ...commonClasses.value,
}));
const rightButtonsContainerClasses = computed(() => ({
  'actions_container__right-buttons': true,
  ...commonClasses.value,
}));
const leftButtonsContainerClasses = computed(() => ({
  'actions_container__left-buttons': true,
  ...commonClasses.value,
}));
const isRegenerateEnabled = computed(() => store.getters.isRegenerateAnswerAvailable);

const isRegenerateInvisible = computed(() => store.getters.isNewChat);

const setAnswerFormattingText = () => {
  if (!textAreaModel.value) {
    resetFormat();
    return;
  }
  store.dispatch('trackEvent', {
    event: 'CustomTemplateSet',
    values: {
      chatSessionId: chatSessionId.value,
      customTemplate: textAreaModel.value,
    },
  });
  store.commit('ENABLE_ACTIVE_ANSWER_FORMAT');
  internalModalModel.value = false;
};
const resetFormat = () => {
  store.commit('CLEAR_SELECTED_TEMPLATE');
  store.commit('DISABLE_ACTIVE_ANSWER_FORMAT');
  store.dispatch('trackEvent', {
    event: 'DefaultTemplateSet',
    values: {
      chatSessionId: chatSessionId.value,
    },
  });
  internalModalModel.value = false;
};
const regenerateAnswer = () => {
  if (!userProfile.value?.uid) {
    localStorage.setItem('authRedirectedUrl', router.currentRoute.value.path);
    if (chatNewMessage.value) {
      localStorage.setItem('chatNewMessage', chatNewMessage.value);
    }

    store.commit('SET_STATE_PROPERTY', {
      property: 'auth_redirected',
      value: true,
    });
    router.push({
      name: 'Register',
    });
  } else {
    store.commit('ENABLE_ACTIVE_ANSWER_FORMAT');
    store.dispatch('regenerateMessage', { regenerateType: 'useCustomTemplate' });
    store.dispatch('trackEvent', {
      event: 'regenerateMessageWithCustomTemplate',
      values: {
        chatSessionId: chatSessionId.value,
        lastQuestion: store.getters.getChatLastQuestion,
        lastAnswer: store.getters.getChatLastAnswer,
        customTemplate: textAreaModel.value,
      },
    });
  }

  internalModalModel.value = false;
};

watch(textAreaModel, (newVal) => {
  countTokens(newVal, MAX_TOKEN_LIMIT);
});
watch(
  [internalModalModel, textAreaModel],
  ([newValModel, newValInput, newValStoredFormat], [, , prevValStoredFormat]) => {
    if (!newValModel && newValInput !== newValStoredFormat) {
      store.dispatch('trackEvent', {
        event: 'ClosedSettingFormat',
        values: {
          chatSessionId: chatSessionId.value,
        },
      });
      return;
    }
    if (!newValModel && newValInput === prevValStoredFormat && newValInput !== '') {
      store.dispatch('trackEvent', {
        event: 'ClosedSettingFormat',
        values: {
          chatSessionId: chatSessionId.value,
        },
      });
      return;
    }
    if (!newValModel && !newValInput && !newValStoredFormat && !prevValStoredFormat) {
      store.dispatch('trackEvent', {
        event: 'ClosedSettingFormat',
        values: {
          chatSessionId: chatSessionId.value,
        },
      });
      return;
    }
  }
);
</script>

<style lang="scss">
.container {
  display: flex;
  flex-direction: column;
  gap: rem(16px);
  margin-right: 4px;
}
.list {
  margin-inline: rem(32px);
  display: flex;
  flex-direction: column;
  gap: rem(4px);
}
.description {
  color: $color-secondary-darken-3;
  @include text14;
  &__flex {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(8px);
  }
}

.textarea-bg {
  & .v-field {
    background-color: rgb(var(--v-theme-bg-darken-1)) !important;
  }
}
.actions_container {
  display: flex;
  justify-content: space-between;
  gap: rem(16px);

  &__nederlands_mobile {
    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: rem(16px);
    }
  }
  &__engels_mobile {
    @media screen and (max-width: 790px) {
      flex-direction: column;
      gap: rem(16px);
    }
  }
  &__left-buttons {
    display: flex;
    gap: rem(16px);
  }
  &__right-buttons {
    display: flex;
    gap: rem(16px);
  }
}

.active-template-toolbar {
  z-index: 1;
  gap: rem(16px);
  display: grid;
  grid-template-columns: min(30%, rem(300px)) 1fr max-content;
  align-items: center;
  & .actions {
    display: flex;
    gap: rem(8px);
  }
}
</style>
