<template>
  <RouterLink class="link" :to="{ name: 'Sources' }">
    <v-list-item key="sources" value="sources" color="primary" variant="text">
      <template #prepend>
        <v-icon
          :icon="'mdi-file-document-multiple'"
          style="margin-right: -24px"
          color="black"></v-icon>
      </template>
      <v-list-item-title>
        {{ t('global.sources') }}
      </v-list-item-title>
    </v-list-item>
  </RouterLink>
</template>
<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
  color: inherit;
}
</style>
