/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { config } from './config';
/**
 * Fetches the chat history list from the server.
 * @param {Object} context - The Vuex context object.
 */

const { cmsUrl } = config

export const getNewsPosts = async ({ commit }, payload) => {
  const { page, pageSize, search = '', category } = payload;

  await axios.get(`${cmsUrl}/blog-posts`, {
    params: {
      pagination: {
        pageSize,
        page
      },
      filters: {
        Category: {
          $eq: category
        },
        Title: {
          $contains: search
        }
      },
      populate: 'Media',
      sort: 'createdAt:asc'
    }
  }).then((res) => {
      commit('SET_STATE_PROPERTY', {
        property: 'news_list',
        value: res.data,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const resetNewsPosts = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', {
    property: 'news_list',
    value: null,
  });
};

export const getNewsArticle = async ({ commit }, payload) => {
  const { id } = payload;
  await axios.get(`${cmsUrl}/blog-posts/${id}`, {
    params: {
      populate: 'Media',
    },
  }).then((response) => {
      commit('SET_STATE_PROPERTY', {
        property: 'news_article',
        value: response.data,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const resetNewsArticle = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', {
    property: 'news_article',
    value: null,
  });
};
