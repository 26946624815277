<template>
  <v-btn
    variant="flat"
    :color="color"
    class="base-button"
    :class="{
      [`base-button--${size}`]: !!size,
      'base-button--max': max,
      'base-button--no-padding': noPadding,
      'base-button--disabled': disabled,
    }"
    elevation="0"
    :disabled="disabled">
    <v-tooltip v-if="disabledTooltipText" activator="parent" location="top" max-width="320">
      {{ disabledTooltipText }}
    </v-tooltip>
    <slot />
    <template #prepend>
      <slot name="prepend" />
    </template>
    <template #append>
      <slot name="append" />
    </template>
  </v-btn>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  max: {
    type: Boolean,
    default: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disabledTooltip: {
    type: [Boolean, String],
    default: false,
  },
  color: {
    type: String,
    required: false,
    default: 'primary',
  },
});

const { size, disabledTooltip, disabled } = toRefs(props);

const isDisabledTooltip = computed(() => disabledTooltip.value !== false && !!disabled.value);
const disabledTooltipText = computed(() => {
  if (!isDisabledTooltip.value) return null;
  return typeof disabledTooltip.value === 'string'
    ? disabledTooltip.value
    : t('fields.fillMandatory');
});
</script>

<style lang="scss" scoped>
.base-button {
  border-radius: rem(32px);
  padding-left: rem(24px);
  padding-right: rem(24px);
  text-transform: none;
  text-decoration: none;
  font-weight: 500;
  &--no-padding {
    padding: 0;
  }
  &--disabled {
    pointer-events: auto;
    cursor: default;
  }
  &.v-btn--size-default {
    height: rem(32px);
  }
  &.v-btn--disabled {
    opacity: 0.5;
    filter: grayscale(1);
  }
  &.v-btn--size-large {
    width: 100%;
    max-width: rem(211px);
    font-size: rem(14px);
  }
  &--max {
    width: 100%;
    &.v-btn--size-large {
      max-width: unset;
    }
  }
  &.v-btn--variant-outlined.text-text {
    border-color: $color-secondary;
  }
}
</style>
