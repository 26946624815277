import chatLeft2 from '@/assets/images/onboarding/chat-left-2.png';
import chatBody1 from '@/assets/images/onboarding/chat-body-1.png';
import answerFormatting from '@/assets/images/onboarding/answer-formatting.png';
import chatRightPanel from '@/assets/images/onboarding/chat-right.png';
import chatLeft1 from '@/assets/images/onboarding/chat-left-1.png';
import goodQuestion from '@/assets/images/onboarding/good-question.png';
import badQuestion from '@/assets/images/onboarding/bad-question.png';
import RegenerateIcon from '@/assets/icons/regenerate.svg';
import CopyIcon from '@/assets/icons/copy.svg';
import LanguageIcon from '@/assets/icons/language.svg';
import chatBody2 from '@/assets/images/onboarding/chat-body-2.png';
import GetInTouchLink from '@/features/Onboarding/components/GetInTouchLink.vue';

const ONBOARDING_DATA = [
  {
    component: 'prompting-tips',
    title: 'Prompting tips',
    img: goodQuestion,
    content: [
      {
        description: 'Provide relevant context, such as the specific disease or condition.',
        example: 'What evidence supports vedolizumab for inducing remission in Crohn’s disease?"',
        isGood: true,
      },
      {
        description: 'Use clear and precise language with direct questions.',
        example: 'What does the study conclude about Drug A vs. Drug B?',
        isGood: true,
      },
      {
        description: 'Include specific details like population and intervention.',
        example: 'Can you draft a table with for each study population and the outcome?',
        isGood: true,
      },
    ],
  },
  {
    component: 'prompting-tips',
    title: 'Prompting tips',
    img: badQuestion,
    content: [
      {
        description: 'Avoid indirect questions and unclear or vague language.',
        example: 'Does sleep play a role in immune function?',
        isGood: false,
      },
      {
        description: 'Avoid broad prompts to ensure accurate results.',
        example: 'Treating autoimmune diseases.',
        isGood: false,
      },
      {
        description: 'Avoid using locally abbreviations not known to the wider medical community.',
        example: 'Does HTN-T2DM impact CKD progression differently than in non-HTN patients?',
        isGood: false,
      },
    ],
  },
  {
    component: 'layout',
    title: 'Layout',
    img: chatLeft1,
    content: [
      {
        title: 'Sources',
        description:
          'Choose sources for evidence analysis. For institutions, you can upload custom guidelines, protocols, or internal documents.',
        additionalComponent: GetInTouchLink,
        additionalComponentProps: { hideOrganizationText: true },
      },
      {
        title: 'Elaborate mode',
        description:
          'Provides a detailed overview with more extensive coverage of relevant evidence.',
      },
      {
        title: 'Precision mode',
        description: 'Generates concise answers with key papers.',
      },
      {
        title: 'Format answer',
        description:
          'Set custom rules to guide the answer format or specify key information to be summarized.',
        additionalComponent: GetInTouchLink,
      },
    ],
  },
  {
    component: 'layout',
    title: 'Layout',
    img: chatLeft2,
    content: [
      {
        title: 'Filters',
        description:
          'Enhance the accuracy of your search by applying filters such as study type, publication date, impact factor, or sample size.',
      },
      {
        title: 'Impact factor',
        description:
          'In "High" mode, only articles from journals in the top two impact factor quartiles are included.',
      },
      {
        title: 'Sample size',
        description:
          'Filter results by setting a minimum sample size, displaying only articles that meet or exceed this threshold.',
      },
      {
        title: 'History',
        description:
          'Check the keywords extracted from your prompt to refine your results with follow-up questions.',
      },
    ],
  },
  {
    component: 'layout',
    title: 'Layout',
    img: chatBody1,
    content: [
      {
        icon: LanguageIcon,
        title: 'Chat language',
        description: 'Change response language to match your audience.',
      },
      {
        icon: RegenerateIcon,
        title: 'Answer regeneration',
        description:
          'Regenerate answers using the same question with different sources or selections.',
      },
      {
        icon: CopyIcon,
        title: 'Answer copying',
        description: 'Copy the answer to paste into a document or share with colleagues.',
      },
    ],
  },
  {
    component: 'layout',
    title: 'Layout',
    img: chatBody2,
    content: [
      {
        title: 'Answer formatting',
        description:
          'For larger instructions, use Format answers to create structured prompts and automated preferences.',
        additionalComponent: GetInTouchLink,
      },
      {
        title: 'Chat sharing',
        description:
          'Generate and copy a unique link to the answer for later reference or sharing with colleagues.',
      },
      {
        title: 'Asking questions',
        description:
          'Dig deeper with a follow-up question or set formatting preferences for tables, summaries, and bias score evaluations.',
      },
    ],
  },

  {
    component: 'layout',
    title: 'Layout',
    img: answerFormatting,
    content: [
      {
        title: 'Answer formatting',
        description:
          'Customize answer formatting with structured prompts. Enable ‘always-on’ to apply preferences automatically for consistent summaries and evaluations. Allows for complete customization of the platform to fit your use cases.',
        additionalComponent: GetInTouchLink,
      },
    ],
  },
  {
    component: 'layout',
    title: 'Layout',
    img: chatRightPanel,
    content: [
      {
        title: 'Answer regeneration',
        description: 'Regenerate your answer instantly using selected evidence for refinement.',
      },
      {
        title: 'Reference exporting',
        description: 'Export all or selected references to your preferred file manager.',
      },
    ],
  },
];

export { ONBOARDING_DATA };
