<template>
  <v-select
    class="base-select text-secondary"
    :class="{
      'base-select--dark': dark,
      'base-select--light': !dark,
    }"
    :bg-color="dark ? 'bg-darken-1' : 'bg'"
    variant="outlined"
    flat
    rounded="lg"
    density="comfortable"
    menu-icon="mdi-chevron-down"
    item-color="primary"
    elevation="0"
    hide-details="auto"
    v-model="singleSelection"
    :menu-props="{ closeOnContentClick: true }">
    <template #item="{ item }">
      <v-list lines="one" select-strategy="classic" v-if="item.raw.subItems?.length">
        <v-list-subheader class="group-name">{{ item.raw.mainItemLabel }}</v-list-subheader>
        <v-list-item
          class="base-select__item"
          :value="`nestedList${index}`"
          v-for="(subItem, index) in item.raw.subItems"
          :key="index">
          <v-list-item-title
            class="v-list-item-title--inner"
            @click="() => setSelect(item.raw.mainItemId, subItem.name)"
            >{{ subItem.name }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
      <v-list lines="one" select-strategy="classic" v-else>
        <v-list-item class="base-select__item" :value="item.raw.mainItemLabel">
          <v-list-item-title
            class="group-name"
            @click="() => setSelect(item.raw.mainItemId, item.raw.mainItemLabel)"
            >{{ item.raw.mainItemLabel }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </template>
  </v-select>
</template>

<script setup>
import { computed, toRefs } from 'vue';
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },

  itemClass: {
    type: String,
    default: '',
  },
  dark: {
    type: Boolean,
    default: false,
  },
});
const { modelValue } = toRefs(props);

const singleSelection = computed({
  get: () => modelValue.value,
  set: (val) => emit('update:modelValue', val),
});

const setSelect = (mainId, subItemName) => {
  singleSelection.value = subItemName;
};
</script>

<style lang="scss" scoped>
.base-select {
  min-width: rem(80px);
  &.v-select--active-menu {
    :deep() {
      .v-field {
        &__clearable {
          display: flex;
        }
      }
    }
  }
  :deep() {
    .v-select {
      &__menu-icon {
        margin: 0;
      }
      &__selection,
      &__selection > * {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
    .v-list {
      box-shadow: unset;
    }
    .v-field {
      height: rem(44px);
      padding-right: rem(4px);
      display: flex;
      .v-field__outline {
        color: $color-secondary;
        --v-field-border-opacity: 0.7;
      }
      &:hover {
        .v-field__outline {
          --v-field-border-opacity: 1;
        }
      }
      &__input {
        padding-left: rem(12px);
        padding-right: 0;
        padding-block: 0;
        input {
          flex-grow: 1;
          align-self: unset;
        }
      }
      &__clearable {
        display: none;
        margin: 0;
      }
      &:focus-within {
        outline-width: 2px;
      }
    }
    .v-field__field {
      height: 100%;
      max-width: 92%;
      .v-label {
        color: $color-text;
      }
    }
    .v-field__input {
      min-height: unset;
      height: 100%;
      font-size: rem(16px);
      font-weight: 500;
      color: $color-text;
    }
  }
  &__item {
    :deep() {
      min-height: unset;
      .v-list-item-title {
        font-size: rem(14px);
        &--inner {
          margin-left: 10px;
        }
      }

      &.v-list-item {
        &--active {
          .v-list-item-title {
            color: rgb(var(--v-theme-text));
            font-weight: 500;
          }
        }
      }
    }
  }
}
.group-name {
  font-size: rem(12px) !important;
  font-weight: 600;
  text-transform: uppercase;
  color: $color-text-lighten-1;
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>
