<template>
  <BaseModal v-model="modelValue" class="onboarding-modal" height="100%">
    <div class="onboarding-modal-content">
      <LayoutSection v-if="currentData.component === 'layout'" :data="currentData" />
      <PromptingTipsSection
        v-else-if="currentData.component === 'prompting-tips'"
        :data="currentData" />
    </div>
    <template #footer>
      <div class="onboarding-modal-content__footer">
        <div>
          <v-icon
            size="x-small"
            v-for="(item, index) in ONBOARDING_DATA"
            :key="item.index"
            class="color-transition"
            :color="currentStep - 1 === index ? '#68a1ff' : 'grey'"
            >mdi-circle</v-icon
          >
        </div>
        <div class="onboarding-modal-content__footer__buttons">
          <BaseButton variant="outlined" :disabled="isPrevDisabled" @click="handlePrevious">{{
            t('global.previous')
          }}</BaseButton>
          <BaseButton @click="handleNext">{{ nextButtonTitle }}</BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>
<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import BaseModal from '@/components/BaseModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import LayoutSection from '@/features/Onboarding/components/LayoutSection.vue';
import PromptingTipsSection from '@/features/Onboarding/components/PromptingTipSection.vue';
import { ONBOARDING_DATA } from '@/features/Onboarding/utils';

const store = useStore();
const { t } = useI18n();
const currentStep = ref(1);

const currentData = computed(() => ONBOARDING_DATA[currentStep.value - 1]);

const modelValue = computed({
  get: () => store.state.onboardingModal,
  set: (val) => store.commit('SET_STATE_PROPERTY', { property: 'onboardingModal', value: val }),
});

const isItLast = computed(() => currentStep.value === ONBOARDING_DATA.length);
const nextButtonTitle = computed(() => (isItLast.value ? t('global.close') : t('global.next')));
const isPrevDisabled = computed(() => currentStep.value === 1);
const handleNext = () => {
  if (currentStep.value === ONBOARDING_DATA.length) {
    modelValue.value = false;
    store.commit('SET_STATE_PROPERTY', { property: 'isOnboardingNeeded', value: false });
    resetCurrentStep();
  } else {
    currentStep.value = currentStep.value + 1;
  }
};
const handlePrevious = () => {
  currentStep.value = (currentStep.value - 1 + ONBOARDING_DATA.length) % ONBOARDING_DATA.length;
};

const resetCurrentStep = () => {
  currentStep.value = 1;
};
</script>
<style lang="scss" scoped>
.onboarding-modal-content {
  margin-top: rem(32px);

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__buttons {
      display: flex;
      gap: rem(8px);
    }
  }
}
.color-transition {
  transition: all 0.5s ease-in-out;
}
.text-transition {
  animation: fadeText 0.5s;
}
@keyframes fadeText {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
