<template>
  <v-divider :thickness="1" style="opacity: 80%" />
  <v-list-subheader class="user-menu-subheader">{{ t('userMenu.switchAccount') }}</v-list-subheader>

  <v-list-item
    v-for="(item, i) in organizationItems"
    :key="`org-switcher-${i}`"
    :value="`org-switcher-${i}`"
    variant="text"
    @click.stop="selectOrganization(item.id)">
    <template #prepend>
      <v-icon :icon="'mdi-office-building'" style="margin-right: -24px" color="black"></v-icon>
    </template>
    <template #append>
      <v-icon
        :icon="getRadioIcon(item.id === activeOrganizationId)"
        size="small"
        color="black"></v-icon>
    </template>

    <v-list-item-title>{{ item.name }}</v-list-item-title>
  </v-list-item>
  <v-list-item
    key="org-switcher-personal"
    value="org-switcher-personal"
    variant="text"
    @click.stop="selectUser">
    <template #prepend>
      <v-avatar color="grey" style="margin-right: -8px; height: 1.5em; width: 1.5em">
        <span class="user-menu-inner-initials">{{ initials }}</span>
      </v-avatar>
    </template>
    <template #append>
      <v-icon :icon="getRadioIcon(activeUserUid)" size="small" color="black"> </v-icon>
    </template>
    <v-list-item-title>{{ displayName }}</v-list-item-title>
  </v-list-item>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const store = useStore();
const router = useRouter();

const organizationItems = computed(() => store.state.organizations);
const displayName = computed(() => store.getters.displayName);

const email = computed(() => store.state.userProfile?.email);
const activeOrganizationId = computed(() => store.state.organization?.id);
const activeUserUid = computed(() => !store.state.organization && store.state.userProfile?.uid);
const initials = computed(
  () =>
    (store.state.userProfile?.firstName?.charAt(0)?.toUpperCase() ||
      email?.value?.charAt(0)?.toUpperCase() ||
      '') + (store.state.userProfile?.lastName?.charAt(0)?.toUpperCase() || '')
);

const selectOrganization = (organizationId) => {
  store.dispatch('selectOrganizationId', { organizationId });
  store.dispatch('newChat');
  store.dispatch('resetOrganizationalSourceStates');
};
const selectUser = () => {
  store.dispatch('selectUser');
  store.dispatch('newChat');
  store.dispatch('resetOrganizationalSourceStates');
};

const getRadioIcon = (value) => {
  return value ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank';
};
</script>

<style lang="scss" scoped>
.user-menu-inner-initials {
  font-size: rem(10px);
}
.user-menu-subheader {
  text-transform: uppercase;
  font-size: rem(10px);
}
</style>
