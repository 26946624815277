<template>
  <div class="footer-content">
    <div class="footer-content__top">
      <img class="footer-content__logo" src="@/assets/icons/logo-spear.svg" alt="logo icon" width="186" height="32">
      <div v-for="item in footerLinks" :key="item.label" class="footer-content__links" :class="{ [`_links-${index}`]: mobile }">
        <span>{{ item.label }}</span>
        <template v-for="(link, index) in item.links" :key="index">
          <a v-if="link.href" :href="link.href" target="_blank">{{ link.title }}</a>
          <RouterLink v-else :to="link.route || { name: 'Home' }">{{ link.title }}</RouterLink>
        </template>
      </div>
      <div class="footer-content__socials">
        <IconLink
          v-for="link in socialLinks"
          :key="link.name"
          :icon="link.icon"
          :name="link.name"
          :link="link.route" />
      </div>
    </div>
    <div class="footer-content__privacy">
      <button
        v-for="link in policyPages"
        :key="link.text"
        class="footer-content__privacy--link"
        variant="text"
        text
        density="compact"
        @click="onPolicyPageClick(link)">
        {{ link.text }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from 'vuetify'
import { useFooterLinks } from '@/composables/useFooterLinks'
import { socialLinks } from '@/utils/social';
import IconLink from '@/components/IconLink.vue';
import usePolicyPages, { useNavigate } from '@/composables/policyPages';

const { mobile } = useDisplay()
const navigate = useNavigate();
const footerLinks = useFooterLinks()
const policyPages = usePolicyPages('footer');
const onPolicyPageClick = (link) => {
  navigate(link);
};
</script>

<style lang="scss" scoped>
.footer-content {

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #007AFF;

    a {
      color: #0F1C37 !important;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 16px;

    .social-media-link {
      background-color: #0362FF !important;
    }
  }

  &__privacy {
    margin-top: 40px;
    padding: 20px 0 33px;
    border-top: 1px solid #DDDFEA;
    display: flex;
    align-items: center;
    gap: 32px;

    &--link {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #0F1C37;
    }
  }

  @include responsive {
    &__top {
      display: grid;
      grid-template-areas:
            "l l"
            "l1 l2"
            "s s";
      grid-template-columns: 1fr 1fr;
      grid-template-areas: auto;
      justify-items: center;
      grid-row-gap: 30px;
    }

    &__logo {
      grid-area: l;
      margin-bottom: 15px;
    }

    &__links {
      &._links-0 {
        grid-area: l1;
      }
      &._links-1 {
        grid-area: l2;
      }
    }

    &__socials {
      width: 100%;
      justify-content: center;
      grid-area: s;
      padding: 15px 0;
      border-top: 1px solid #DDDFEA;
      border-bottom: 1px solid #DDDFEA;
    }

    &__privacy {
      border-top: none;
      margin-top: 0;
      justify-content: center;
    }
  }
}
</style>
