<template>
  <v-menu max-width="300" v-bind="menuProps">
    <template v-slot:activator="{ props, isActive }">
      <slot
        name="activator"
        :activatorProps="props"
        :isMenuOpen="isActive"
        :isLoading="isLoading"></slot>
    </template>
    <v-list>
      <v-list-item
        v-for="recentOutputTemplate in recentOutputTemplates"
        @click="emit('selectTemplate', recentOutputTemplate)"
        :key="recentOutputTemplate.id"
        :title="recentOutputTemplate.name"
        :subtitle="recentOutputTemplate.prompt">
        <template v-slot:prepend>
          <OutputTemplateCreator :owned-by-type="recentOutputTemplate.ownedByType" />
        </template>
      </v-list-item>
      <v-list-item
        v-if="!disableSeeMoreOptions"
        class="font-italic"
        @click="emit('seeMoreTemplates')"
        :title="t('answerFormatting.seeMoreTemplates')">
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import OutputTemplateCreator from '@/features/OutputTemplate/components/OutputTemplateCreator.vue';
import { computed, onMounted, ref } from 'vue';
import * as Sentry from '@sentry/browser';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const props = defineProps({
  menuProps: {
    type: Object,
    required: false,
  },
  disableSeeMoreOptions: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['selectTemplate', 'seeMoreTemplates']);

const store = useStore();
const { t } = useI18n();

const isLoading = ref(false);
const recentOutputTemplates = computed(
  () => store.state.recentlyUsedOutputTemplateListResource?.outputTemplates || []
);

onMounted(async () => {
  try {
    isLoading.value = true;
    await store.dispatch('fetchRecentlyUsedOutputTemplateListResource');
  } catch (error) {
    Sentry.captureException(error);
  } finally {
    isLoading.value = false;
  }
});
</script>
