<template>
  <v-btn
    color="primary"
    variant="text"
    class="btn-class"
    :ripple="false"
    append-icon="mdi-chevron-down">
    {{ label }}

    <v-menu class="header-submenu" activator="parent" open-on-hover="" >
      <v-list>
        <v-list-item
          v-for="(item, index) in children"
          class="header-submenu__item"
          :key="index"
          :value="item?.to || item?.href || index"
          :component="item?.to ? 'router-link' : 'a'"
          :to="item?.to"
          :href="item?.href"
          :target="item?.href ? '_blank' : undefined">
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>
<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  children: {
    type: Array[Object],
    default: [],
  },
  label: {
    type: String,
    default: '',
  },
});

const { children, label } = toRefs(props);
</script>
<style lang="scss" scoped>
.btn-class {
  font-family: 'Red Hat Display', 'Nunito', 'Charter', Arial, sans-serif;
  font-weight: 500;
  color: rgb(var(--v-theme-text)) !important;
  height: 54px;
  border-radius: 0px;
  border-bottom: 2px solid transparent;

  &:hover {
    color: #007AFF !important;
    border-color: #007AFF !important;

    .v-btn__overlay {
      opacity: 1;
    }
  }
}
.btn-class.v-btn--variant-plain {
  opacity: 1;
}

.header-submenu {
  &__item {
    min-width: 225px;
    color: rgb(var(--v-theme-text)) !important;
    text-decoration: none !important;

    &:not(:last-child) {
      border-bottom: 1px solid #DDDFEA;
    }

    &:hover {
      color: #007AFF !important;
    }
  }
}
</style>
