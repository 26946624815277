<template>
  <div class="footer-try">
    <h2 class="footer-try__title">Try EvidenceHunt yourself, ask a question</h2>
    <div class="footer-try__content">
      <PageQuestionInput style="max-width: 374px;" />
      <button class="footer-try__btn" @click="openContactModal">
        <img src="@/assets/icons/edit-icon.svg" alt="edit icon" width="20" height="20">
        <span>Contact us</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { getRouteName } from '@/utils';
import PageQuestionInput from '@/components/PageQuestionInput.vue'

const store = useStore()
const route = useRoute()

const openContactModal = () => {
  const routeName = getRouteName(route, true);

  store.commit('SET_STATE_PROPERTY', {
    property: 'contactModal',
    value: true,
  });
  store.dispatch('trackEvent', {
    event: 'ContactClicked',
    values: {
      page: routeName,
      content: 'email_form',
      location,
    },
  });
}

</script>

<style lang="scss" scoped>
.footer-try {
  padding: 40px 50px;
  border-radius: 32px;
  color: #fff;
  background: linear-gradient(123.51deg, #0F1C37 52.36%, #023B99 80.75%, #0363FF 109.71%);
  margin-bottom: 70px;

  &__title {
    font-size: 32px !important;
    line-height: 66px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  &__content {
    display:flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    padding: 15.5px 88px;
    border-radius: 32px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    gap: 14px;
    cursor: pointer;
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
  }

  @include responsive {
      padding: 30px 24px;

      &__title {
        font-size: 28px !important;
        line-height: 48px;
        text-align: center;
      }

      &__content {
        flex-direction: column;
        gap: 32px;
      }

      &__btn {
        padding-inline: 55px;
      }
  }
}
</style>
